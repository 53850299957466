import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { articles } from "../../contents/articles";
import React, { useState, useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const BlogCardsHomePage = () => {
  const [articlesData, setArticlesData] = useState([]);
  const [hover, setHover] = useState({});
  // const Blogs = articles.slice(0, 3);
  const Blogs = articlesData;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  function stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_URL}/articles`);
        setArticlesData(response.data);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="w-full">
      <div className="w-full mx-auto px-12 lg:px-[13%]">
        <div className="text-center py-4">
          <h1
            className="text-2xl font-bold  mt-8 font-sarabunFont
                text-content"
          >
            บทความ
          </h1>
          <div className="w-[36px] h-[7px] bg-primary mt-4 mb-3 rounded-xl block mx-auto" />
        </div>

        <div
          className="
              grid grid-rows-1 md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2
              mx-auto justify-center items-center w-full md:gap-6 gap-2 
            "
        >
          {Blogs.filter((blog) => blog.isApproved === true)
            .slice(0, 3)
            .map((blog) => (
              <Link
                onClick={handleClick}
                to={`/articles/${blog.id}`}
                key={blog.id}
                className=" shadow-lg
                    relative  
                    group  cursor-pointer items-center justify-between overflow-hidden 
                    hover:shadow-black/50 hover:shadow-xl transition-shadow 
                    hover:opacity-60 rounded-xl group my-4 
      
                  "
              >
                <div className="">
                  <img
                    src={blog.image}
                    alt="wedding"
                    style={{ width: "full", height: "276px" }}
                    className="transition-transform w-full h-full
                     duration-200 group-hover:rotate-1 group-hover:scale-110"
                  />
                  <div
                    className="absolute inset-0 lg:flex flex-row 
                      translate-x-[-60%] items-center gap-4
                      justify-center px-9 transition-all duration-75 bottom-36
                      group-hover:translate-x-0 text-slate-600 hidden"
                  >
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                  </div>
                </div>

                <div className="relative">
                  <div
                    className="absolute bg-primary text-white p-1 
                      rounded-md top-[-38px] right-[42%]"
                  >
                    <h1 className=" font-sarabunFont">บทความ</h1>
                  </div>
                  <h3
                    className=" mb-2 font-extrabold text-center font-sarabunFont
                    hover:text-primary text-[22px] mx-8 text-[#2D2A2A]"
                  >
                    {blog.title}
                  </h3>
                  <p
                    className="mt-8 mb-2 mx-4 text-center font-sarabunFont 
                    text-[#777777] line-clamp-2"
                  >
                    {`${stripHtml(blog.title)}${stripHtml(
                      blog.textContent || ""
                    )}`
                      .slice(0, 148)
                      .concat("...")}
                  </p>

                  <div
                    onMouseEnter={() => setHover({ [blog.id]: true })}
                    onMouseLeave={() => setHover({ [blog.id]: false })}
                    className="flex justify-center items-center
                    "
                  >
                    <p
                      className={`mb-6 mt-2 text-primary uppercase 
                        font-textFont ${hover ? "" : ""}`}
                    >
                      {blog.ContinueRead}
                    </p>
                    <div className="flex flex-row gap-1">
                      {[...Array(3)].map((_, i) => (
                        <FontAwesomeIcon
                          icon={faCircle}
                          key={i}
                          className={`-mt-[6px] text-[4px]
                              ${
                                hover[blog.id]
                                  ? "hoverAnimate text-primary"
                                  : "hidden"
                              }
                              `}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <div>
          <button className="block mx-auto bg-primary p-3 my-12 rounded-md hover:opacity-95">
            <Link
              onClick={handleClick}
              to="/articles"
              className="text-white font-sarabunFont"
            >
              บทความทั้งหมด
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCardsHomePage;
