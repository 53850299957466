export const page_1 = {
    content1: `เมื่อบุพเพสันนิวาสมั่นหมายให้คู่กัน เรือนไทยคุ้มเจ้าสัวขอเป็นส่วนหนึ่งได้มอบความตราตรึงแห่งวันประทับใจ
    ด้วยมนต์เสน่ห์ของความเป็นไทย ในการจัดพิธีแบบไทยอันงดงาม ที่จะพาคุณและคนที่คุณรัก 
    ข้ามภพจากใจกลางเมืองกรุงไปสัมผัสกับบรรยากาศ ของไทยอย่างแท้จริง`
    ,
    content2: `คุ้มเจ้าสัว ตั้งอยู่บนถนนรามอินทรา บนพื้นที่มากกว่า 10 ไร่ ด้วยการตกแต่งที่เป็นแบบไทยแท้แบบโบราณ
    ทั้งบริเวณโดยรอบ และตัวอาคาร ที่สร้างเหมือนเรือนไทยในสมัยอยุธยา เก็บรายละเอียดทุกมุมได้อย่างมีเอกลักษณ์เฉพาะตัว
    เพิ่มมนต์เสน่ห์ของความเป็นไทยเข้าไป พร้อมด้วยบรรยากาศแนวย้อนยุค เหมือนดังละครดังบุพเพสันนิวาส ทำให้การจัดงานนั้น 
    มีความสมบูรณ์แบบอย่างลงตัว`
}

