import { useContext } from "react";
// import { VariablesContext } from "../../pages/HomePage";
import { VariablesContext } from "../../App";

const ImageCard = ({ imgSrc, style, ref }) => {
    const variables = useContext(VariablesContext);
    return (
        <div className={`relative sm:max-w-[90%] max-w-[90%] overflow-hidden 
            md:max-w-[45%] shadow-lg group lg:max-w-[45%]`}>
            <img 
            src={imgSrc} 
            className={`transition-transform group-hover:scale-110 duration-200 cursor-pointer ${variables[1][2] ? style : ""}`}        
            ref={(el) => (variables[0].current[2] = el)}
            alt="wedding"
            />
            {/* <div className="absolute inset-0 flex items-end bg-gradient-to-to
            from-black/60 to-transparent">
                <div className={`p-4 text-while`}>{children}</div>
            </div> */}
        </div>
    )   
}

export default ImageCard;