import ImageCard from "../ImageCard/ImageCard";
import weddingPic01 from "../../assets/WeddingPicture/wedding-pic-01.jpg";
import weddingPic02 from "../../assets/WeddingPicture/wedding-pic-02.jpg";
import weddingPic03 from "../../assets/WeddingPicture/wedding-pic-03.jpg";
import weddingPic04 from "../../assets/WeddingPicture/wedding-pic-04.jpg";

import { Link } from "react-router-dom";
import { useContext } from "react";
import { VariablesContext } from "../../App";

const ImageContent = () => {
    const variables = useContext(VariablesContext);
    return (
        <>
        <main className="flex flex-wrap mt-8 gap-8 justify-center px-[10%]">
            <ImageCard
            imgSrc={weddingPic01}
            style="bannerYAnimate"
            />
            <ImageCard
            imgSrc={weddingPic02} 
            style="bannerYAnimate"
            />
            <ImageCard 
            imgSrc={weddingPic03} 
            style="thirdAnimate"
            />
            <ImageCard
            imgSrc={weddingPic04}
            style="thirdAnimate"
            />
        </main>
        <Link 
        onClick={() => (window.scrollTo({ top: 0, behavior: "auto" }))}
        to="/location" 
        className={`my-12 inline mx-auto rounded-md bg-secondary p-3 px-4 text-white
            ${variables[1][3] ? "thirdAnimate" : "" }
         font-sarabunFont`}
        ref={(el) => (variables[0].current[3] = el)}
        >          
            <h1>ดูสถานที่จัดงาน</h1>          
        </Link>
        </>
    )
}

export default ImageContent;