import React, { useEffect } from "react";

import HomePageSlide from "../components/SlidePicture/HomePageSlide.jsx";
import ImageContent from "../components/ImageContent/ImageContent.jsx";
import WeddingSlide from "../components/SlidePicture/WeddingSlide.jsx";
import Banner from "../components/Banner.jsx";
import BlogCardsHomePage from "../components/BlogCards/BlogCardsHomePage.jsx";
import PackageCard from "../components/PackageCards/PackageCards.jsx";
import ButtonToTop from "../components/ButtonToTop.jsx";
import SubFooter from "../components/subFooter.jsx";

function HomePage() {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const unload = () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scriptSelector =
        "script[src='https://www.google.com/recaptcha/api.js?render=" +
        RECAPTCHA_SITE_KEY +
        "']";
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
    };
    unload();
  }, []);

  return (
    <div className="mt-20 flex flex-col relative">
      <HomePageSlide link={true} />
      <Banner />
      <ImageContent />
      <WeddingSlide link={true} />
      <PackageCard />
      <BlogCardsHomePage />
      <SubFooter />
      <ButtonToTop showBelow={150} />
    </div>
  );
}

export default HomePage;
