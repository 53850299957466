import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Navbar from "../../components/Navbar/Navbar";
import { useState, useEffect } from "react";
import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Header = () => {
  const [contacts, setContacts] = useState({});
  useEffect(() => {
    axios
      .get(`${BACKEND_API_URL}/company`, {
        timeout: 5000, // Timeout of 5 seconds
      })
      .then((response) => {
        if (response.status === 200) {
          setContacts(response.data[0]);
        } else {
        }
      });
  }, []);

  return (
    <header className="w-full h-auto bg-secondary z-10 py-3 ">
      <div className="flex flex-col md:flex-row md:text-base mb-2 justify-between items-center px-[13%]">
        <div className="flex items-center">
          {contacts?.salePerson && (
            <div className="text-xs text-white font-extralight flex items-center mr-4">
              <a
                href={`tel:${contacts.salePerson[0].phone}`}
                className="phone cursor-pointer mr-2"
              >
                Tel.: {contacts.salePerson[0].phone.replace(/\s+/g, "-")} |{" "}
              </a>
              <a
                href={`mailto:${contacts.email}`}
                className="email cursor-pointer"
              >
                Email: {contacts.email}
              </a>
            </div>
          )}
          {!["kcs-prod-2024.as.r.appspot.com", "khumchaosua.com"].includes(
            window.location.hostname
          ) && (
            <div className="bg-gray-900 text-white px-2 py-1 text-sm font-bold text-center">
              non-prod
            </div>
          )}
        </div>

        <div className="lg:flex flex-row gap-4 text-sm hidden">
          <Link to={contacts?.facebook} target="_blank" className="">
            <FontAwesomeIcon
              className="text-white cursor-pointer hidden md:block"
              icon={faFacebookF}
            />
          </Link>
          <Link to={contacts?.instagram} target="_blank" className="">
            <FontAwesomeIcon
              className="text-white cursor-pointer hidden md:block"
              icon={faInstagram}
            />
          </Link>
        </div>
      </div>
      <Navbar />
    </header>
  );
};

export default Header;
