import React, { useContext, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import lineThai from "../../assets/line_thai.jpg";
import { VariablesContext } from "../../App";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
function PackageCard() {
  const variables = useContext(VariablesContext);
  const [packagesData, setPackagesData] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${API_URL}/packages`);
        setPackagesData(response.data);
      } catch (error) {
        console.error("Failed to fetch package:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${lineThai})`,
      }}
      className={`mt-0 py-8 relative h-[80%] bg-cover px-[10%]`}
    >
      <div className="text-center py-8">
        <h1 className="text-2xl text-white font-sarabunFont font-bold">
          เเพ็กเกจ
        </h1>
        <div className="w-[36px] h-[7px] bg-primary rounded-xl block mx-auto mt-4" />
      </div>
      <div className="w-full mx-auto px-3 sm:px-12">
        <div className="grid grid-rows-1 md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-8 mt-10 ">
          {packagesData
            .filter((eachpackage) => eachpackage.isApproved === true)
            .map((eachpackage) => (
              <Link
                to={eachpackage.image}
                title={eachpackage.title}
                alt={eachpackage.description}
                target="_blank"
                className={`cursor-pointer ${
                  variables[1][4] ? "bannerYAnimate" : "hidden"
                }`}
                ref={(el) => (variables[0].current[4] = el)}
              >
                <div
                  className="image-container"
                  style={{
                    backgroundImage: `url(${eachpackage.image})`,
                    width: "100%",
                    height: "340px",
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>{" "}
              </Link>
            ))}
        </div>
        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
          to="/packages"
          className={`flex my-12 w-fit mx-auto bg-primary p-3 rounded-md
    text-white ${variables[1][4] ? "bannerYAnimate" : ""}
    font-sarabunFont`}
          ref={(el) => (variables[0].current[4] = el)}
        >
          <h1>ดูเเพ็กเกจทั้งหมด</h1>
        </Link>
      </div>
    </div>
  );
}

export default PackageCard;
