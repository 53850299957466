import React from "react";

function Footer() {
  return (
    <footer>
      <p className="h-[50px] bg-secondary text-white flex 
      justify-center items-center text-xs font-textFont font-extralight">
        © 2022 Khum Chao Sua Wedding. All rights reserved
      </p>
    </footer>
  );
}

export default Footer;
