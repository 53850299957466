import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faXmark, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const SideBar = ({ blogs }) => {
  const Blogs = blogs;

  const handleClick = () => {
    window["scrollTo"]({ top: 0, behavior: "auto" });
  };

  return (
    <div className="top-2 md:flex md:flex-col gap-4 hidden p-4">
      <div className="text-xl font-semiblod px-1 font-sarabunFont">
        บทความล่าสุด
      </div>
      <div>
        {Blogs.filter((blog) => blog.isApproved === true).map((blog) => (
          <Link
            onClick={handleClick}
            to={`/articles/${blog.id}`}
            key={blog.id}
            className="grid grid-cols-[35%_65%]"
          >
            <div
              className=" 
                flex justify-center
                group cursor-pointer overflow-hidden relative mt-2
                hover:shadow-black/30 hover:shadow-xl transition-shadow 
              "
            >
              <img
                src={blog.image}
                className="transition-transform group-hover:scale-110 
                  duration-200 group-hover:rotate-3 object-cover"
                alt="blog"
              />
              <div
                className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent 
                  to-black group-hover:from-black/70 group-hover:via-black/60 
                  group-hover:to-black/70"
              ></div>
              <div
                className="absolute inset-0 flex flex-row gap-1 translate-x-[-80%] items-center justify-center px-9
                text-center transition-all duration-500 group-hover:translate-x-0 text-white text-[8px]"
              >
                <FontAwesomeIcon icon={faCircle} />
                <FontAwesomeIcon icon={faCircle} />
                <FontAwesomeIcon icon={faCircle} />
              </div>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div
                className="absolute flex flex-col  
                pr-4 rounded-sm hover:shadow-2xl ml-4"
              >
                <p className=" font-sarabunFont text-start w-[90%] ml-2 text-sm">
                  {blog.title}
                </p>
              </div>
            </div>

            <div className="w-full bg-gray-300 h-[1px] my-[18px]" />
            <div className="w-full bg-gray-300 h-[1px] my-[18px]" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export const SidebarResponsive = ({ blogs }) => {
  const Blogs = blogs;
  const [icon, setIcon] = useState(true);

  const handleClick = () => {
    window["scrollTo"]({ top: 0, behavior: "auto" });
  };

  return (
    <div className="h-screen bg-white w-[100%] ">
      <div
        className="flex justify-end py-4 gap-1 items-center text-gray-800"
        onMouseEnter={() => setIcon(false)}
        onMouseLeave={() => setIcon(true)}
      >
        <h1>CLOSE</h1>
        {icon ? (
          <FontAwesomeIcon
            icon={faXmark}
            className={`w-4 h-8 text-center text-[24px] p-1`}
          />
        ) : (
          <FontAwesomeIcon
            icon={faMinus}
            className={`w-4 h-8 text-center text-[24px] p-1`}
          />
        )}
      </div>

      <div className="w-full h-[1px] my-4 bg-gray-300 " />

      <h1 className="mb-6 flex mx-2 font-bold font-sarabunFont">
        บทความล่าสุด
      </h1>

      <div className="w-full flex flex-col gap-4">
        {Blogs.map((blog) => (
          <Link
            onClick={handleClick}
            to={`/articles/${blog.id}`}
            key={blog.id}
            className="w-[100%] grid grid-cols-[35%_65%] items-center px-4"
          >
            <img src={blog.image} className="bg-cover px-1" alt="blog" />
            <div className="text-center text-sm text-stone-600 hover:text-gray-400 font-sarabunFont">
              {blog.title}
            </div>
            <div className="w-full bg-gray-300 h-[1px] mt-8" />
            <div className="w-full bg-gray-300 h-[1px] mt-8" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
