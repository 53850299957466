import React, { useContext, useEffect, useRef } from "react";
import BlogCards from "../components/BlogCards/BlogCards";
import ButtonToTop from "../components/ButtonToTop.jsx";
import SubFooter from "../components/subFooter.jsx";
import { VariablesContext } from "../App";

function ArticlesPage() {
  const variables = useContext(VariablesContext);
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const unload = () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scriptSelector =
        "script[src='https://www.google.com/recaptcha/api.js?render=" +
        RECAPTCHA_SITE_KEY +
        "']";
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
    };
    unload();
  }, []);

  return (
    <>
      <div
        className={`mt-20 mx-3 lg:mx-[12%] relative cursor-pointer ${
          variables && variables[1] ? "bannerYAnimate" : ""
        }`}
        ref={(el) => {
          if (variables && variables[0]) {
            variables[0].current[0] = el;
          }
        }}
      >
        <BlogCards />
        <ButtonToTop showBelow={150} />
      </div>
      <SubFooter />
    </>
  );
}

export default ArticlesPage;
