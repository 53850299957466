import React, { useState, useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import SideBar, { SidebarResponsive } from "../Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faPinterest,
  faLinkedinIn,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonToTop from "../ButtonToTop";
import { VariablesContext } from "../../App";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const SingleBlog = () => {
  const variables = useContext(VariablesContext);
  const [articlesData, setArticlesData] = useState([]);
  const { id } = useParams();
  const filterBlogs = articlesData.filter((a) => Number(a.id) === Number(id));
  const blog = filterBlogs[0];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const socialItems = [
    {
      path: "https://www.facebook.com/KhumchaosuaWedding/",
      link: faFacebookF,
      id: 1,
      styleCircle: "relative w-10 h-10 rounded-full bg-blue-900",
      styleIcon: "absolute top-3 right-[15px] text-white text-md",
    },
    {
      path:
        "https://twitter.com/intent/post?url=https%3A%2F%2Fkhumchaosua.com%2F%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%99%2F",
      link: faTwitter,
      id: 2,
      styleCircle: "relative w-10 h-10 rounded-full bg-sky-400",
      styleIcon: "absolute top-3 right-3 text-white text-md",
    },
    {
      path:
        "https://www.pinterest.com/pin/create/button/?url=https://khumchaosua.com/%e0%b9%83%e0%b8%ab%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%81%e0%b8%95%e0%b9%88%e0%b8%87%e0%b8%82%e0%b8%ad%e0%b8%87%e0%b8%84%e0%b8%b8%e0%b8%93%e0%b9%80%e0%b8%ab%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%99/&media=https://khumchaosua.com/wp-content/uploads/2022/06/james-bold-loGgEYdYMLk-unsplash-scaled.jpg&description=%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%99%E0%B8%94%E0%B8%B1%E0%B9%88%E0%B8%87%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%21+%E0%B8%81%E0%B8%B1%E0%B8%9A+9+%E0%B8%AA%E0%B8%B4%E0%B9%88%E0%B8%87%E0%B8%AA%E0%B8%B3%E0%B8%84%E0%B8%B1%E0%B8%8D%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%AB%E0%B9%89%E0%B8%B2%E0%B8%A1%E0%B8%9E%E0%B8%A5%E0%B8%B2%E0%B8%94",
      link: faPinterest,
      id: 3,
      styleCircle: "relative w-10 h-10 rounded-full bg-red-600",
      styleIcon: "absolute top-3 right-3 text-white text-md",
    },
    {
      path:
        "https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Fmini%3Dtrue%26url%3Dhttps%3A%2F%2Fkhumchaosua.com%2F%25e0%25b9%2583%25e0%25b8%25ab%25e0%25b9%2589%25e0%25b8%2587%25e0%25b8%25b2%25e0%25b8%2599%25e0%25b9%2581%25e0%25b8%2595%25e0%25b9%2588%25e0%25b8%2587%25e0%25b8%2582%25e0%25b8%25ad%25e0%25b8%2587%25e0%25b8%2584%25e0%25b8%25b8%25e0%25b8%2593%25e0%25b9%2580%25e0%25b8%25ab%25e0%25b8%25a1%25e0%25b8%25b7%25e0%25b8%25ad%25e0%25b8%2599%2F",
      link: faLinkedinIn,
      id: 4,
      styleCircle: "relative w-10 h-10 rounded-full bg-blue-500",
      styleIcon: "absolute top-3 right-3 text-white text-md",
    },
    {
      path:
        "https://telegram.me/share/url?url=https://khumchaosua.com/%e0%b9%83%e0%b8%ab%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%81%e0%b8%95%e0%b9%88%e0%b8%87%e0%b8%82%e0%b8%ad%e0%b8%87%e0%b8%84%e0%b8%b8%e0%b8%93%e0%b9%80%e0%b8%ab%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%99/",
      link: faTelegram,
      id: 5,
      styleCircle: "relative w-10 h-10 rounded-full bg-cyan-500",
      styleIcon: "absolute top-3 right-3 text-white text-md",
    },
  ];

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_URL}/articles`);
        setArticlesData(response.data);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchArticles();
  }, []);

  if (!blog) {
    // Render a loading message, a spinner, or return null
    return <div>Loading...</div>;
  }

  return (
    <div className="relative w-full mx-auto px-4 lg:px-[12%]">
      <div
        // className="relative mt-36 grid lg:grid-cols-[70%_30%] md:grid-cols-[65%_35%] sm:grid-cols-1 "
        className={`relative mt-36 grid lg:grid-cols-[70%_30%] md:grid-cols-[65%_35%] sm:grid-cols-1 cursor-pointer ${
          variables && variables[1] ? "bannerYAnimate" : "hidden"
        }`}
        ref={(el) => {
          if (variables && variables[0]) {
            variables[0].current[0] = el;
          }
        }}
      >
        <div
          className={`md:hidden fixed bg-white z-10 
                ${
                  isMenuOpen
                    ? "top-0 left-0 w-[50%] overflow-scroll z-30"
                    : "top-56 left-0 rounded-r-3xl border-gray-300 border-[1px]"
                }
            `}
        >
          <button onClick={toggleMenu} className="">
            {isMenuOpen ? (
              <SidebarResponsive blogs={articlesData} />
            ) : (
              <div className="p-4 my-2 flex flex-row justify-center items-center gap-2 ">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="w-1 h-1 text-gray-500"
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className="w-1 h-1 text-gray-500"
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className="w-1 h-1 text-gray-500"
                />
              </div>
            )}
          </button>
        </div>
        <div
          key={blog.id}
          className="flex flex-col gap-4 relative z-0 
            font-sarabunFont text-[#777777] "
        >
          <h1
            className="mx-auto text-[#2D2A2A] 
                text-[20px] lg:text-[32px] md:text-[28px] sm:text-[24px]
                font-semibold mb-4 text-center w-[100%]"
          >
            {blog.title}
          </h1>

          <img src={blog.image} alt="wedding" className="w-[100%] mx-auto" />
          <h1 className=" w-[100%] mx-auto my-2 text-lg font-bold ">
            {blog.title}
          </h1>
          <h1
            className="mx-auto w-[100%] text-base text-[#777777]"
            dangerouslySetInnerHTML={{ __html: blog.textContent }}
          ></h1>
          <div className="h-[1px] bg-slate-400 w-full mt-20" />

          <div className="flex flex-row justify-center items-center gap-2 mt-8 mb-12">
            {socialItems.map((item) => (
              <Link
                key={item.id}
                to={item.path}
                target="_blank"
                className={item.styleCircle}
              >
                <FontAwesomeIcon icon={item.link} className={item.styleIcon} />
              </Link>
            ))}
          </div>
        </div>

        <aside className="w-full mt-8 md:mt-0">
          <div className="sticky top-4 overflow-y-auto max-h-screen pb-8">
            <SideBar blogs={articlesData} />
          </div>
        </aside>

        <ButtonToTop showBelow={250} />
      </div>
    </div>
  );
};

export default SingleBlog;
