import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const MenuItem = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1050px)" });
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef(null);
  const location = useLocation();

  const handleClickOutside = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = (
    <div ref={node}>
      <NavLink
        to="/"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        หน้าเเรก
      </NavLink>
      <NavLink
        to="/wedding"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        งานเเต่งงาน
      </NavLink>
      <NavLink
        to="/packages"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        เเพ็กเกจ
      </NavLink>
      <NavLink
        to="/location"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        สถานที่
      </NavLink>
      <NavLink
        to="/articles"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        บทความ
      </NavLink>
      <NavLink
        to="/contact"
        className="text-sm  px-3  hover:text-primary focus:text-primary"
      >
        ติดต่อเรา
      </NavLink>
    </div>
  );

  const modalMenu = (
    <div
      className="fixed -top-4 left-0 w-64 h-[2000px] bg-[#fff] shadow-md 
    flex flex-col pt-8 gap-4"
    >
      <NavLink
        to="/"
        className="text-sm px-6  hover:text-primary focus:text-primary"
      >
        หน้าเเรก
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
      <NavLink
        to="/wedding"
        className="text-sm  px-6  hover:text-primary focus:text-primary"
      >
        งานเเต่งงาน
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
      <NavLink
        to="/packages"
        className="text-sm  px-6  hover:text-primary focus:text-primary"
      >
        เเพ็กเกจ
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
      <NavLink
        to="/location"
        className="text-sm  px-6  hover:text-primary focus:text-primary"
      >
        สถานที่
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
      <NavLink
        to="/articles"
        className="text-sm  px-6  hover:text-primary focus:text-primary"
      >
        บทความ
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
      <NavLink
        to="/contact"
        className="text-sm  px-6  hover:text-primary focus:text-primary"
      >
        ติดต่อเรา
      </NavLink>
      <div className="w-full h-[1px] bg-[#abb8c3]" />
    </div>
  );

  return (
    <div className="w-full ">
      {isSmallScreen ? (
        <div
          className="flex justify-start items-center
        "
        >
          <button onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          {isOpen && modalMenu}
        </div>
      ) : (
        <div className="py-2 transition cursor-pointer flex items-center justify-center">
          {menuItems}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
