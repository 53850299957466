import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";

export default function ButtonToTop({ showBelow }){
    const [show, setShow] = useState(showBelow ? false : true );
    const handleScroll = () => {
        if(window.scrollY > showBelow){
            if(!show) setShow(true)
        }
        else {
            if(show) setShow(false)
        }
    }
    useEffect(() => {
        if(showBelow){
            window.addEventListener("scroll", handleScroll)
            return () => window.removeEventListener("scroll", handleScroll)
        }
    })
    const handleClick = () => {
        window["scrollTo"]({ top: 0, behavior: "smooth" })
    }

    return (
        <div 
        onClick={handleClick}
        className={`${show ? `fixed md:w-16 md:h-16 w-12 h-12 rounded-full 
        bg-white bg-opacity-90 right-4 bottom-12 cursor-pointer
        shadow-md border-2 buttonXAnimate` : "hidden"}
        `}>
            <FontAwesomeIcon 
            icon={faChevronUp}
            className=" text-[#333333] md:text-[32px] text-[18px] absolute 
            md:right-[14px] md:top-4 right-[12px] top-[13px]"
            />
        </div>
    )
}