import React, { useState, useEffect } from "react";
import Footer_Logo from "../assets/footer_logo.jpg";
import MapLocation from "./MapLocation";
import { Link } from "react-router-dom";
import axios from "axios";
// import { contacts } from "../contents/contactsData";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

function SubFooter() {
  const [isBackendConnected, setIsBackendConnected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState({});

  const checkBackendConnectivity = () => {
    axios
      .get(`${BACKEND_API_URL}/company`, {
        timeout: 5000, // Timeout of 5 seconds
      })
      .then((response) => {
        if (response.status === 200) {
          setContacts(response.data[0]);
          setIsBackendConnected(true);
        } else {
          setIsBackendConnected(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled\n", error.message);
        } else if (error.code === "ECONNABORTED") {
          console.log("A timeout has occurred");
          setIsBackendConnected(false);
        } else {
          setIsBackendConnected(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    checkBackendConnectivity();
  }, []);

  const socialItems = [
    {
      path: "https://www.facebook.com/KhumchaosuaWedding/",
      link: faFacebookF,
      id: 1,
      styleCircle: "relative w-10 h-10 rounded-full bg-white",
      styleIcon: "absolute top-2 right-[13px] text-bgSubFooter text-[24px]",
    },
    {
      path: "https://www.instagram.com/khumchaosua/",
      link: faInstagram,
      id: 2,
      styleCircle: "relative w-10 h-10 rounded-full bg-white",
      styleIcon: "absolute top-[6px] right-[8px] text-bgSubFooter text-[28px]",
    },
  ];

  return (
    <div
      className="bg-bgSubFooter h-[100%] flex px-[13%]
    lg:flex-row md:flex-row sm:flex-col flex-col p-10 gap-12"
    >
      <div className="my-4 lg:w-[50%] md:w-[45%]">
        <div className=" pb-4">
          <img src={Footer_Logo} width={220} alt="Khumchaosua logo" />
        </div>
        <div
          className="mx-auto flex flex-col gap-2 text-white 
         font-sarabunFont"
        >
          <h1 className="font-bold">ที่อยู่ :</h1>
          <p>{contacts.address}</p>
          <h1 className="font-bold">เบอร์ติดต่อ :</h1>
          {contacts?.salePerson?.map((person) => (
            <>
              <div className="flex flex-row text-sm">
                <h1>{person.phone} </h1>&nbsp;
                <h1>({person.name})</h1>
              </div>
            </>
          ))}
          <div className="flex flex-row justify-start items-center gap-2">
            <FontAwesomeIcon icon={faEnvelope} />
            <h1>อีเมล :</h1>
          </div>
          <h1>{contacts.email}</h1>
          <div className="flex flex-row gap-4 mt-8">
            {socialItems.map((item) => (
              <Link
                key={item.id}
                to={
                  item.link == faFacebookF
                    ? contacts.facebook
                    : contacts.instagram
                }
                target={item.id}
                className={item.styleCircle}
              >
                <FontAwesomeIcon icon={item.link} className={item.styleIcon} />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-[100%] my-4 mx-auto ">
        <p className="text-white font-bold font-sarabunFont">แผนที่ร้าน :</p>
        <div className="bg-gray-300 h-[100%] mt-4">
          <MapLocation />
        </div>
      </div>
    </div>
  );
}

export default SubFooter;
