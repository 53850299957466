import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;
const BlogCards = () => {
  const [articlesData, setArticlesData] = useState([]);
  const [hover, setHover] = useState({});
  const [hoverBlog, setHoverBlog] = useState({});
  const Blogs = articlesData;

  const handleClick = () => {
    window["scrollTo"]({ top: 0, behavior: "auto" });
  };

  function stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_URL}/articles`);
        setArticlesData(response.data);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="mb-16 w-full">
      <div className="w-full mx-auto">
        <div className="text-center py-4">
          <h1
            className="text-2xl py-4 mt-6 text-textColorArticle font-medium
            font-sarabunFont"
          >
            บทความ
          </h1>
          <div className="w-[36px] h-[7px] bg-primary rounded-xl block mx-auto" />
        </div>

        <div
          className="
          flex flex-col lg:flex-row flex-wrap gap-6 w-full
          mx-auto justify-center items-center
        "
        >
          {Blogs.filter((blog) => blog.isApproved === true).map((blog) => (
            <Link
              onMouseEnter={() => setHoverBlog({ [blog.id]: true })}
              onMouseLeave={() => setHoverBlog({ [blog.id]: false })}
              onClick={handleClick}
              to={`/articles/${blog.id}`}
              key={blog.id}
              className="
                   group
                  relative my-6
                  overflow-hidden place-items-center
                  grid md:grid-cols-[50%_50%] grid-rows
                "
            >
              <div className="">
                <img
                  src={blog.image}
                  alt="wedding"
                  className="w-full group-hover:scale-110 scale-110
                  duration-200 group-hover:rotate-0 object-cover cursor-pointer"
                />
                <div
                  className="translate-x-[0] absolute inset-0 w-[50%]
                  transition-all duration-75 group-hover:translate-x-0
                  bg-gradient-to-b group-hover:scale-110 group-hover:rotate-1
                  to-black md:group-hover:from-black/40
                  md:group-hover:via-black/40
                  md:group-hover:to-black/40
                  "
                >
                  <div
                    className={`
                    ${
                      hoverBlog[blog.id]
                        ? `md:absolute md:flex md:flex-row md:justify-center hidden
                        md:items-center md:gap-4 md:text-white md:top-[50%]
                        md:right-[40%]`
                        : "hidden"
                    }`}
                  >
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                  </div>
                </div>
              </div>

              <div
                className="w-full h-full sm:h-[80%]
                flex justify-center items-center"
              >
                <div
                  className="container relative flex flex-col gap-1 bg-white
                    rounded-sm p-4 cursor-pointer py-8
                    mr-2 border-[2px] border-gray-100
                  "
                >
                  <div
                    className="absolute bg-primary text-white p-1 rounded-md
                      -top-[5%] right-[42%] "
                  >
                    <h1 className=" font-sarabunFont">บทความ</h1>
                  </div>
                  <h3
                    className=" mb-2 font-extrabold text-center font-sarabunFont
                    hover:text-primary text-[22px] mx-8 text-[#2D2A2A]"
                  >
                    {blog.title}
                  </h3>
                  <p
                    className="mt-4 mb-4 mx-8 text-center font-sarabunFont
                    text-[#777777] text-[14px]"
                  >
                    {`${stripHtml(blog.title)}${stripHtml(
                      blog.textContent || ""
                    )}`
                      .slice(0, 148)
                      .concat("...")}
                  </p>

                  <div
                    onMouseEnter={() => setHover({ [blog.id]: true })}
                    onMouseLeave={() => setHover({ [blog.id]: false })}
                    className="flex justify-center items-center
                    "
                  >
                    <p
                      className={` text-primary uppercase
                        font-textFont`}
                    >
                      Continue Reading
                    </p>
                    <div className="flex flex-row gap-1">
                      {[...Array(3)].map((_, i) => (
                        <FontAwesomeIcon
                          icon={faCircle}
                          key={i}
                          className={`mt-[7px] text-[4px]
                            ${
                              hover[blog.id]
                                ? "hoverAnimate text-primary"
                                : "hidden"
                            }
                            `}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
