import React, { useEffect, useState, useContext } from "react";
import SubFooter from "../components/subFooter";
import ButtonToTop from "../components/ButtonToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { VariablesContext } from "../App";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function LocationPage() {
  const variables = useContext(VariablesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortField, setSortField] = useState("id");
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [curr, setCurr] = useState(0);
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const unload = () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scriptSelector =
        "script[src='https://www.google.com/recaptcha/api.js?render=" +
        RECAPTCHA_SITE_KEY +
        "']";
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
    };
    unload();
  }, []);

  const closeImage = () => {
    setModalIsOpen(false);
  };

  const prev = () => {
    setCurr((curr) => (curr === 0 ? images.length - 1 : curr - 1));
  };
  const next = () => {
    setCurr((curr) => (curr === images.length - 1 ? 0 : curr + 1));
  };

  const handleEscKey = (event) => {
    if (event.key === "Escape") {
      closeImage();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(`${API_URL}/pictures`);
        const sortedData = response.data.sort((a, b) => {
          if (a[sortField] < b[sortField])
            return sortDirection === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField])
            return sortDirection === "asc" ? 1 : -1;
          return 0;
        });
        const weddingPhotos = sortedData
          .filter(
            (photo) => photo.type === "place" && photo.isApproved === true
          )
          .map((photo) => ({
            src: photo.image,
            id: photo.id,
          }));
        setImages(weddingPhotos);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch photos:", error);
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <div onKeyDown={handleEscKey} className="w-full h-full mt-36">
      <div className="w-full mx-auto px-8 lg:px-[13%]">
        <p
          className="text-textColorArticle text-2xl 
          mb-3 text-center font-medium
          font-sarabunFont"
        >
          สถานที่จัดงาน
        </p>
        <div
          className="w-[50px] h-[10px] bg-primary 
        rounded-xl block mx-auto mb-8"
        />

        <div
          className="grid grid-cols-2 mb-16
          sm:grid-cols-3 md:grid-cols-4 gap-2"
        >
          {images.map((image, index) => (
            <div
              className={`cursor-pointer ${
                variables && variables[1] ? "bannerYAnimate" : "hidden"
              }`}
              ref={(el) => {
                if (variables && variables[0]) {
                  variables[0].current[0] = el;
                }
              }}
              key={index}
            >
              <img
                alt={`location ${index}`}
                onClick={() => [setModalIsOpen(true), setCurr(index)]}
                src={image.src}
                loading="lazy"
                className="w-full h-full sm:h-[200px] object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <SubFooter />
      <ButtonToTop />

      {/* modal */}
      {modalIsOpen ? (
        <div
          className="fixed z-40 w-full mx-auto px-1 
            sm:px-[1%] overflow-y-hidden
          bg-white h-screen top-0 bg-opacity-80 "
        >
          <div className="flex justify-between items-center mt-2 pt-1">
            <div className="text-black text-xl font-bold px-4 lg:px-16">
              {curr + 1}/{images.length}
            </div>
            <FontAwesomeIcon
              icon={faX}
              onClick={closeImage}
              className="text-black font-bold cursor-pointer 
                  px-4 lg:mx-16 text-2xl"
            />
          </div>

          <div
            className=" w-full h-full
          flex justify-center items-center
          "
          >
            <div
              className="flex-[5%] h-[10%] flex 
            justify-center items-center"
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={prev}
                className="text-black text-3xl 
                cursor-pointer font-bold"
              />
            </div>
            <div className="w-full sm:h-[80%] lg:w-[80%]">
              <img
                alt={`location ${curr}`}
                src={images[curr].src}
                key={curr}
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="flex-[5%] h-[10%] flex 
            justify-center items-center"
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={next}
                className="text-black text-3xl 
                cursor-pointer font-bold"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default LocationPage;
