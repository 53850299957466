import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import MapLocation from "../components/MapLocation.jsx";
import ButtonToTop from "../components/ButtonToTop.jsx";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = () => {};

const ContactPage = () => {
  const variables = [null, null];
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const formRef = React.createRef();
  const unsafeCharactersRegex = /[^a-zA-Z0-9 .,?!@#$%^&*()\u0E00-\u0E7F]/;
  const safeEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const regexInput = /^[ก-๙a-zA-Z0-9\s]+$/;
  const socialItems = [
    {
      link: faFacebookF,
      id: 1,
      styleCircle: "relative w-10 h-10 rounded-full bg-textColor",
      styleIcon: "absolute top-2 right-[13px] text-white text-[24px]",
    },
    {
      link: faInstagram,
      id: 2,
      styleCircle: "relative w-10 h-10 rounded-full bg-textColor",
      styleIcon: "absolute top-[6px] right-[8px] text-white text-[28px]",
    },
  ];
  const [isBackendConnected, setIsBackendConnected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contactNameValid, setContactNameValid] = useState(true);
  const [contactMobileValid, setContactMobileValid] = useState(true);
  const [contactEmailValid, setContactEmailValid] = useState(true);
  const [contactMessageValid, setContactMessageValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [contacts, setContacts] = useState({});

  useEffect(() => {
    checkBackendConnectivity();
  }, []);

  useEffect(() => {
    // Create script element and set attributes
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;

    // Append the script to the <head>
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const validateForm = (data) => {
    const contactMobile = data.contactMobile.trim().replace(/-/g, "");

    if (!data.contactName || !data.contactEmail || !data.contactMessage) {
      toast.error("Please fill out mandatory (*) fields.", { autoClose: 3000 });
      return false;
    }

    if (
      unsafeCharactersRegex.test(data.contactName) ||
      !regexInput.test(data.contactName)
    ) {
      toast.error("Name contains invalid characters.", { autoClose: 3000 });
      return false;
    }
    setContactNameValid(!!data.contactName);

    if (unsafeCharactersRegex.test(data.contactEmail)) {
      toast.error("Email contains invalid characters.", { autoClose: 3000 });
      return false;
    }

    if (
      unsafeCharactersRegex.test(data.contactMessage) ||
      !regexInput.test(data.contactMessage)
    ) {
      toast.error("Message contains invalid characters.", { autoClose: 3000 });
      return false;
    }
    setContactMessageValid(!!data.contactMessage);

    if (
      unsafeCharactersRegex.test(data.contactMobile) ||
      !regexInput.test(data.contactMobile)
    ) {
      toast.error("Name contains invalid characters.", { autoClose: 3000 });
      return false;
    }

    if (!safeEmailRegex.test(data.contactEmail)) {
      toast.error("Please enter a valid email address.", { autoClose: 3000 });
      return false;
    }
    setContactEmailValid(!!data.contactEmail);

    if (!/^\d{9,10}$/.test(contactMobile)) {
      toast.error("Please enter a valid mobile number.", { autoClose: 3000 });
      return false;
    }
    setContactMobileValid(!!contactMobile); // this variable is different from others because it was checked before

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData);
    executeRecaptcha();
  };

  const executeRecaptcha = () => {
    if (window.grecaptcha) {
      window.grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: "submit" })
        .then((token) => {
          onResolved(token);
        });
    } else {
      throw new Error("Recaptcha script not loaded");
    }
  };

  const sendData = async (authToken) => {
    const formData = new FormData(formRef.current);
    const entriesArray = Array.from(formData.entries());
    const data = Object.fromEntries(entriesArray);
    const updatedFormData = { ...data };
    if (validateForm(data)) {
      axios
        .post(`${BACKEND_API_URL}/forms`, updatedFormData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Form submitted successfully", {
              onClose: () => {
                setFormSubmitted(true);
              },
              autoClose: 1000,
            });
          } else {
            toast.error("Form submission not success. Please try again.", {
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            toast.error("You do not have permission to perform this action.", {
              autoClose: 3000,
            });
          } else {
            toast.error("Form submission failed. Please try again.", {
              autoClose: 3000,
            });
          }
        });
    }
  };

  const onResolved = (token) => {
    const fetchRecaptcha = new Promise((resolve, reject) => {
      if (!BACKEND_API_URL) {
        reject(new Error("Backend API URL is not defined"));
        return;
      }

      axios({
        method: "post",
        url: `${BACKEND_API_URL}/verifyRecaptcha`,
        data: { token },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log("Response: ", response);
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .then((data) => resolve(data))
        .catch((error) => {
          if (axios.isCancel(error)) {
            // console.log("Request canceled", error.message);
          } else {
            // console.error("Error verifying reCAPTCHA: ", error.message);
            reject(new Error("Failed to verify reCAPTCHA. Please try again."));
          }
        });
    });

    fetchRecaptcha
      .then((data) => {
        if (data.success && data.score >= 0.5) {
          sendData(data.token);
        } else {
          toast.error("reCAPTCHA verification failed. Please try again.", {
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Request timed out") {
          toast.error(
            "The server is taking too long to respond. Please try again later."
          );
        } else {
          toast.error("Promise error:" + error.message, { autoClose: 3000 });
        }
      });
  };

  const checkBackendConnectivity = () => {
    axios
      .get(`${BACKEND_API_URL}/company`, {
        timeout: 5000, // Timeout of 5 seconds
      })
      .then((response) => {
        if (response.status === 200) {
          setContacts(response.data[0]);
          setIsBackendConnected(true);
        } else {
          setIsBackendConnected(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled\n", error.message);
        } else if (error.code === "ECONNABORTED") {
          // console.log("A timeout has occurred");
          setIsBackendConnected(false);
        } else {
          setIsBackendConnected(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-28 overflow-x-hidden mx-auto ">
      <h1
        className="text-center text-2xl text-textColorArticle font-medium mt-8
     font-sarabunFont"
      >
        ติดต่อเรา
      </h1>
      <div className="w-[36px] h-[7px] bg-textColor rounded-xl block mx-auto mt-4" />

      {/* <div
          className={`grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 sm:place-content-start gap-4 md:gap-6 lg:gap-8 mt-16 mx-auto max-w-7xl animate-fadeIn ${
            variables[1] ? "bannerYAnimate" : "hidden"
          }`}
          ref={(el) => {
            if (variables && variables[0]) {
              variables[0].current[0] = el;
            }
          }}
        > */}
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 sm:place-content-start gap-4 md:gap-6 lg:gap-8 mt-16 mx-auto max-w-7xl">
        <div className="mx-[48px] ml-[60px]  flex flex-col gap-2 font-sarabunFont text-gray-500 ">
          <div
            className="flex flex-row justify-start items-center 
        gap-2"
          >
            <FontAwesomeIcon icon={faLocationArrow} />
            <h1 className="font-bold">ที่อยู่:</h1>
          </div>
          <div className="text-content text-sm leading-loose">
            <p>{contacts.address}</p>
          </div>
          <div className="flex flex-row justify-start items-center gap-2">
            <FontAwesomeIcon icon={faMobileScreenButton} />
            <h1 className="font-bold">เบอร์ติดต่อ:</h1>
          </div>
          {contacts?.salePerson?.map((person, index) => (
            <>
              <div key={index} className="flex flex-row text-sm">
                <h1 className="text-primary">{person.phone} </h1>&nbsp;
                <h1>({person.name})</h1>
              </div>
            </>
          ))}
          <div className="flex flex-row justify-start items-center gap-2">
            <FontAwesomeIcon icon={faEnvelope} />
            <h1 className="font-bold">อีเมล:</h1>
          </div>
          <h1 className="text-primary">{contacts.email}</h1>
          <div className="flex flex-row gap-4 mt-8">
            {socialItems.map((item) => (
              <Link
                key={item.id}
                to={
                  item.link == faFacebookF
                    ? contacts.facebook
                    : contacts.instagram
                }
                target={item.id}
                className={item.styleCircle}
              >
                <FontAwesomeIcon icon={item.link} className={item.styleIcon} />
              </Link>
            ))}
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {!formSubmitted ? (
              <form
                title="Contact Form"
                ref={formRef}
                onSubmit={handleSubmit}
                className=" font-sarabunFont flex flex-col gap-3 justify-center h-[95%] items-start 
"
              >
                <div className="flex flex-col gap-4 justify-center items-start w-[90%] mx-auto">
                  <h1 className=" text-contactColor font-normal text-sm">
                    ชื่อ-นามสกุล (Firstname-Lastname):*
                  </h1>
                  <input
                    type="text"
                    title="Please enter your first and last name."
                    name="contactName"
                    className={`pl-4 border-[2px] ${
                      contactNameValid ? "border-stone-200" : "border-red-500"
                    } outline-stone-200 
          rounded-md h-10 w-[100%] font-sarabunFont
          `}
                  />
                </div>

                <div className="flex flex-col gap-4 justify-center items-start w-[90%] mx-auto">
                  <h1 className=" text-contactColor font-normal text-sm">
                    เบอร์ติดต่อ (Mobile Phone Number):*
                  </h1>
                  <input
                    type="mobilephone"
                    title={`${
                      contactMobileValid
                        ? "Enter your mobile phone number. "
                        : "Should only contain numbers, no - or spaces allowed."
                    }`}
                    name="contactMobile"
                    className={`pl-4 border-[2px] ${
                      contactMobileValid ? "border-stone-200" : "border-red-500"
                    } outline-stone-200 
          rounded-md h-10 w-[100%] font-primaryFont`}
                  />
                </div>

                <div className="flex flex-col gap-4 justify-center items-start w-[90%] mx-auto">
                  <h1 className=" text-contactColor font-normal text-sm">
                    อีเมล (Email):*
                  </h1>
                  <input
                    type="email"
                    name="contactEmail"
                    title="Please enter your email."
                    className={`pl-4 border-[2px] ${
                      contactEmailValid ? "border-stone-200" : "border-red-500"
                    } outline-stone-200 
          rounded-md h-10 w-[100%] font-primaryFont`}
                  />
                </div>

                <div className="flex flex-col gap-4 justify-center items-start w-[90%] mx-auto">
                  <h1 className=" text-contactColor font-normal text-sm">
                    ข้อความ (Message):*
                  </h1>
                  <textarea
                    name="contactMessage"
                    title="The message should be at least 5 characters long."
                    className={`pt-2 pl-4 border-[2px] ${
                      contactMessageValid
                        ? "border-stone-200"
                        : "border-red-500"
                    } 
            outline-stone-200 rounded-md h-40 w-[100%]`}
                  />
                </div>

                <button
                  type="submit"
                  className="bg-textColor mt-2 rounded-lg px-6 py-3 mb-24
        mx-[28px]"
                >
                  {/* <ToastContainer position="bottom-center" /> */}
                  <h1 className="text-white font-normal text-sm">
                    ส่งข้อความ (Send)
                  </h1>
                </button>
              </form>
            ) : (
              // <div
              //   className={`font-sarabunFont flex flex-col gap-4 justify-center items-start grid place-items-center ${
              //     this.variables && this.variables[1]
              //       ? "bannerYAnimate"
              //       : "hidden"
              //   }`}
              //   ref={(el) => {
              //     if (this.variables && this.variables[0]) {
              //       this.variables[0].current[0] = el;
              //     }
              //   }}
              // >
              <div className="font-sarabunFont flex flex-col gap-4 justify-center items-start place-items-center">
                <h1>
                  Thank you for your submission! We will contact you as soon as
                  possible. By the way, if you need to contact us immediately,
                  please check on the left handside.
                  <br />
                  <br />
                  ขอบคุณที่กรอกข้อมูล เราจะติดต่อท่านโดยเร็วที่สุด
                  แต่หากท่านต้องการสอบถามข้อมูลเพิ่มเติมในทันที
                  กรุณาใช้ช่องทางการติดต่อทางด้านซ้ายมือ
                </h1>
              </div>
            )}
          </div>
        )}
        <div className="pr-12 mb-8">
          <MapLocation />
        </div>
      </div>

      <ButtonToTop showBelow={50} />
    </div>
  );
};

export default ContactPage;
