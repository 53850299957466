import { useContext } from "react";
import Logo from "../components/Navbar/Logo.jsx";
import { page_1 } from "../contents/page1.js";
import { VariablesContext } from "../App.js";

const Banner = () => {
  const variables = useContext(VariablesContext);

  return (
    <div className="py-8 mx-[13%]" ref={(el) => (variables[0].current[1] = el)}>
      <div
        className={`flex justify-center ${
          variables[1][1] ? "bannerYAnimate" : ""
        }`}
      >
        <Logo width="300" />
      </div>
      <p
        className={`text-content my-5 
            text-center ${variables[1][1] ? "bannerYAnimate" : ""}
            `}
      >
        {page_1.content1}
      </p>
      <p
        className={`text-content
            text-center ${variables[1][1] ? "bannerYAnimate" : ""}
            font-sarabunFont text-content
            `}
      >
        {page_1.content2}
      </p>
    </div>
  );
};

export default Banner;
