import weddingPic41 from "../../assets/WeddingPicture/wedding-pic-41.jpg";
import locationPic153 from "../../assets/LocationPicture/Location-pic-153.jpg";
import locationPic159 from "../../assets/LocationPicture/Location-pic-159.jpg";
import Carousel from "../Carousel/Carousel";

const slides = [
  { img: locationPic159, link: "สถานที่จัดงาน", path: "/location" },
  { img: weddingPic41, link: "อัลบั้มงานเเต่ง", path: "/wedding" },
  { img: locationPic153, link: "เเพ็คเก็จงานเเต่ง", path: "/packages" },
];

export default function HomePageSlide({ link }) {
  return <Carousel images={slides} refId={0} link={link} />;
}
