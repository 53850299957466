const MapLocation = () => (
  <div className=" h-[100%]">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15493.885105052626!2d100.6092504!3d13.8707456!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e282aa5474d039%3A0x543ecabda440df39!2z4LiE4Li44LmJ4Lih4LmA4LiI4LmJ4Liy4Liq4Lix4LinLEtodW1jaGFvc3VhIOC4o-C4suC4oeC4reC4tOC4meC4l-C4o-C4siDguIvguK3guKIgNQ!5e0!3m2!1sth!2sth!4v1712989229572!5m2!1sth!2sth"
      width="600"
      height="450"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      className="w-[100%]"
    ></iframe>
  </div>
);

export default MapLocation;
