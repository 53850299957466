import { useState, useEffect, useContext } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

import { VariablesContext } from "../../App";

export default function Carousel({
  images = [{}],
  refId,
  autoSlide = false,
  autoSlideInterval = 5000,
  link,
}) {
  const [curr, setCurr] = useState(0);

  const prev = () => {
    setCurr((curr) => (curr === 0 ? images.length - 1 : curr - 1));
  };
  const next = () => {
    setCurr((curr) => (curr === images.length - 1 ? 0 : curr + 1));
  };

  useEffect(() => {
    if (!autoSlide) return;

    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  const variables = useContext(VariablesContext);

  return (
    <div
      className=" w-[100%] flex flex-col justify-center 
        items-center "
    >
      <div
        className="carousel w-[100%] h-[200px] lg:h-[74vh] sm:h-[300px]
            md:h-[450px] 
        "
      >
        <div
          className="flex justify-center items-center w-full h-full bg-cover bg-center bg-no-repeat relative"
          style={{
            backgroundImage: `url(${images[curr].img})`,
            backgroundPosition: "center -150px",
            backgroundSize: "cover",
            backgroundSize: "105%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            className="absolute inset-0"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          ></div>
          <div
            className="left h-[15%] bg-opacity-60 grid 
                justify-end items-center cursor-pointer ml-4 relative z-10
                "
            style={{ flex: "10%" }}
          >
            <ChevronLeft
              onClick={prev}
              className="text-white cursor-pointer
                        lg:block lg:w-[80px] lg:h-[80px]
                        md:block md:w-[60px] md:h-[60px]
                        sm:hidden hidden 
                    "
            />
          </div>

          <div
            style={{ flex: "80%" }}
            className="center h-[100%] grid place-items-center
                text-justify text-white md:gap-16 gap-0 relative z-10"
          >
            <div className="" />
            <div
              className="flex flex-col justify-center items-center gap-2 "
              ref={(el) => (variables[0].current[refId] = el)}
            >
              <h2
                className={`text-xs md:text-2xl lg:text-2xl
                        ${variables[1][refId] ? "firstAnimate" : ""}
                        font-[textFont]`}
              >
                Your Dream Wedding Is Here
              </h2>
              <h1
                className={`font-[primaryFont] text-4xl 
              sm:text-6xl md:text-7xl lg:text-7xl font-medium
              ${variables[1][refId] ? "secondaryAnimate" : ""}
              `}
              >
                Khum Chao Sua
              </h1>
              {link ? (
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  className={`
                                text-white bg-primary 
                                md:mt-5 md:p-3 rounded-sm text-[10px] p-1 
                                hover:bg-secondary md:text-[15px]
                                ${variables[1][refId] ? "thirdAnimate" : ""}
                                text-navigationSize font-navigationWeight font-sarabunFont`}
                  to={images[curr].path}
                >
                  {images[curr].link}
                </Link>
              ) : (
                ""
              )}
            </div>

            <div
              className="flex justify-center items-center gap-2 md:gap-6 cursor-pointer
                    "
            >
              {images.map((_, i) => (
                <FontAwesomeIcon
                  icon={faCircle}
                  onClick={() => setCurr(i)}
                  key={i}
                  className={`
                            transition-all bg-transparent rounded-full opacity-80 
                            text-white md:p-[12px] p-[4px]
                            ${
                              curr === i
                                ? "p-2 border-white border-2"
                                : "bg-opacity-50"
                            }
                            `}
                />
              ))}
            </div>
          </div>

          <div
            className="right h-[15%] bg-opacity-60 grid 
                justify-start items-center mr-4 relative z-10"
            style={{ flex: "10%" }}
          >
            <ChevronRight
              onClick={next}
              className="text-white cursor-pointer
                        lg:block lg:w-[80px] lg:h-[80px]
                        md:block md:w-[60px] md:h-[60px]
                        sm:hidden hidden 
                    "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
