import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useRef } from "react";
import Logo from "./Logo";
import MenuItem from "./MenuItem";
import Search from "./Search";

const Navbar = () => {
  return (
    <nav className="absolute w-full top-12 bg-white z-20 border-b border-gray-300 lg:px-[10%]">
      <div
        className="
                flex items-center justify-between lg:mx-6 mr-4
             
        ">
      
        <Logo hidden="hidden" />
        <MenuItem />
        <Search />
      </div>
    </nav>
  );
};

export default Navbar;

