import Header from "./Sections/Header/Header.jsx";
import Footer from "./Sections/Footer/Footer.jsx";

import ArticlesPage from "./pages/ArticlesPage.jsx";
import ContactPage from "./pages/ContactPage.jsx";
import HomePage from "./pages/HomePage.jsx";
import PackagePage from "./pages/PackagePage.jsx";
import LocationPage from "./pages/LocationPage.jsx";
import WeddingPage from "./pages/WeddingPage.jsx";
import SingleBlog from "./components/SingleBlog/SingleBlog.jsx";

import { Routes, Route, useLocation } from "react-router-dom";

import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const VariablesContext = React.createContext();

ReactGA.initialize("G-VJV73JKYF1"); // replace with your Google Analytics tracking id

function App() {
  let location = useLocation();
  const [showComponents, setShowComponents] = useState([]);
  const componentsRef = useRef([]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const checkComponents = () => {
      const triggerBottom = window.innerHeight / 0.5;

      setShowComponents(
        componentsRef.current.map((box) => {
          if (box) {
            const boxTop = box.getBoundingClientRect().top;
            return boxTop < triggerBottom;
          }
          return false;
        })
      );
    };

    window.addEventListener("scroll", checkComponents);
  }, []);

  return (
    <div>
      <Header />
      <VariablesContext.Provider value={[componentsRef, showComponents]}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/wedding" element={<WeddingPage />} />
          <Route path="/packages" element={<PackagePage />} />
          <Route path="/location" element={<LocationPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/articles/:id" element={<SingleBlog />} />
        </Routes>
      </VariablesContext.Provider>
    <ToastContainer />
      <Footer />
    </div>
  );
}

export { VariablesContext };
export default App;
