import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { articles } from "../../contents/articles";

import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";

const Search = () => {
  const [input, setInput] = useState([]);

  return (
    <>
      <div className="relative mx-6 hidden md:hidden lg:block">
        <input
          type="search"
          placeholder="Search..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="w-[320px] p-3 rounded-lg bg-[whitesmoke] text-black outline-none"
        />
        <button
          title="Search"
          className="absolute right-1 top-1/2 -translate-y-1/2 p-3
                bg-[whitesmoke] rounded-full text-black"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
        <div
          className="absolute w-full bg-[whitesmoke] flex flex-col 
                gap-3 rounded-md"
        >
          {articles
            .filter((val) => {
              if (input === "") {
                return val;
              } else if (val.article.includes(input)) {
                return val;
              }
            })
            .map((val) => {
              return (
                input.length > 0 && (
                  <Link
                    to={`/articles/${val.id}`}
                    key={val.id}
                    className="flex flex-row mx-auto text-[12px]
                                justify-center items-center"
                  >
                    <img src={val.image} className="w-[40%] bg-cover" alt="article" />
                    <p className="mx-2">{val.article}</p>
                  </Link>
                )
              );
            })}
        </div>
      </div>

      {/* show component when less md */}
      <div
        className="lg:hidden flex flex-row  gap-6 text-[18px] 
                 my-6 md:my-3"
      >
        <Link
          to="https://www.facebook.com/KhumchaosuaWedding/"
          target="_blank"
          className=""
        >
          <FontAwesomeIcon
            className="text-[#00000099] cursor-pointer "
            icon={faFacebookF}
          />
        </Link>
        <Link
          to="https://www.instagram.com/khumchaosua/"
          target="_blank"
          className=""
        >
          <FontAwesomeIcon
            className="text-[#00000099] cursor-pointer "
            icon={faInstagram}
          />
        </Link>
      </div>
    </>
  );
};

export default Search;
