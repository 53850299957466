import logo from "../../assets/logo_ban.jpg"
import { Link } from "react-router-dom";

const Logo = ({ width, hidden }) => {
    return (
        <Link
        to="/"
        className="mix-blend-multiply px-6"
        >
        <img
        alt="Logo"
        className={`${hidden} lg:block cursor-pointer pb-4 py-4`}
        src={logo}
        width={width || 250}
        />
        </Link>    
    )
}

export default Logo;