import React, { useContext, useEffect } from "react";
import lineThai from "../assets/line_thai.jpg";
import SubFooter from "../components/subFooter";
import ButtonToTop from "../components/ButtonToTop";
import { VariablesContext } from "../App";
import axios from "axios";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
function PackagePage() {
  const variables = useContext(VariablesContext);
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [packagesData, setPackagesData] = React.useState([]);

  useEffect(() => {
    const unload = () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scriptSelector =
        "script[src='https://www.google.com/recaptcha/api.js?render=" +
        RECAPTCHA_SITE_KEY +
        "']";
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
    };
    unload();
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${API_URL}/packages`);
        setPackagesData(response.data);
      } catch (error) {
        console.error("Failed to fetch package:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${lineThai})`,
        }}
        className={`mt-20 pt-4 w-full bg-cover`}
      >
        <div className="text-center pt-10 pb-4">
          <h1 className="text-2xl text-white font-sarabunFont ">เเพ็กเกจ</h1>
          <div className="w-[36px] h-[7px] bg-primary rounded-xl block mx-auto mt-4" />
        </div>
        <div
          className="flex lg:flex-row md:flex-col sm:flex-col flex-col 
      gap-8 justify-center items-center mt-10 pb-20 mx-[13%]"
        >
          {packagesData
            .filter((eachpackage) => eachpackage.isApproved === true)
            .map((eachpackage) => (
              <Link
                to={eachpackage.image}
                target="_blank"
                className={`cursor-pointer ${
                  variables[1] ? "bannerYAnimate" : ""
                }`}
                ref={(el) => (variables[0].current[0] = el)}
              >
                <img
                  src={eachpackage.image}
                  className="cursor-pointer"
                  alt={eachpackage.description}
                />
              </Link>
            ))}
        </div>
      </div>
      <SubFooter />
      <ButtonToTop showBelow={50} />
    </>
  );
}

export default PackagePage;
