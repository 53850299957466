import React, { useEffect, useState, useContext } from "react";
import SlidePicture2 from "../components/SlidePicture/WeddingSlide";
import Modal from "react-modal";
import SwipeableViews from "react-swipeable-views";
import { ChevronLeft, ChevronRight } from "react-feather";
import SubFooter from "../components/subFooter";
import ButtonToTop from "../components/ButtonToTop";
import { VariablesContext } from "../App";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function WeddingPage() {
  const variables = useContext(VariablesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortField, setSortField] = useState("id");
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const unload = () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scriptSelector =
        "script[src='https://www.google.com/recaptcha/api.js?render=" +
        RECAPTCHA_SITE_KEY +
        "']";
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
    };
    unload();
  }, []);

  const openImage = (src, index) => {
    setSelectedImage(src);
    setSelectedIndex(index);
    setModalIsOpen(true);
  };

  const closeImage = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(`${API_URL}/pictures`);
        const sortedData = response.data.sort((a, b) => {
          if (a[sortField] < b[sortField])
            return sortDirection === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField])
            return sortDirection === "asc" ? 1 : -1;
          return 0;
        });
        const weddingPhotos = sortedData
          .filter(
            (photo) => photo.type === "wedding" && photo.isApproved === true
          )
          .map((photo) => ({
            src: photo.image,
            id: photo.id,
          }));
        setImages(weddingPhotos);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch photos:", error);
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <div className="mt-12">
      <SlidePicture2 link={false} />
      <div className="my-10">
        <p className="text-textColorArticle text-2xl mb-4 text-center font-sarabunFont font-medium">
          ภาพงานแต่งงาน
        </p>
        <div className="w-[50px] h-[10px] bg-primary rounded-xl block mx-auto" />
      </div>
      <div
        className="mx-[13%] grid grid-cols-2 mb-16
          sm:grid-cols-3 md:grid-cols-4 gap-2"
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`wedding ${index}`}
            className={`w-full h-auto cursor-pointer ${
              variables && variables[1] ? "bannerYAnimate" : ""
            }`}
            ref={(el) => {
              if (variables[0] && variables[0].current) {
                variables[0].current[0] = el;
              }
            }}
            onClick={() => {
              const popupImage = image.src;
              openImage(popupImage, index);
            }}
          />
        ))}
      </div>
      <SubFooter />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeImage}
        className=" overflow-y-hidden bg-transparent w-[80%] h-[80%] my-[5.6%] mx-[10%] flex justify-center items-center"
      >
        <div className="flex border-none shadow-3xl">
          <button onClick={prevImage}>
            <ChevronLeft size={40} />
          </button>
          <SwipeableViews index={selectedIndex} className="shadow-2xl">
            {images.map((image, index) => (
              <div key={index}>
                <img
                  alt={`wedding ${index}`}
                  src={image.src}
                  loading="lazy"
                  className="rounded-md w-full h-auto object-contain mb-4"
                />
              </div>
            ))}
          </SwipeableViews>
          <button onClick={nextImage}>
            <ChevronRight size={40} />
          </button>
        </div>
      </Modal>
      <ButtonToTop showBelow={150} />
    </div>
  );
}

export default WeddingPage;
