import articleImg01 from "../assets/articlesPicture/article-img01.jpg";
import articleImg02 from "../assets/articlesPicture/article-img02.jpg";
import articleImg03 from "../assets/articlesPicture/article-img03.jpg";
import articleImg04 from "../assets/articlesPicture/article-img04.jpg";
import articleImg05 from "../assets/articlesPicture/article-img05.jpg";

export const articles = [
  {
    article:
      "ให้งานเเต่งของคุณเหมือนดั่งความฝัน! กับ 9 สิ่งสำคัญที่คุณห้ามพลาด",
    id: 1,
    image: articleImg01,
    introduction: `เป็นธรรมดาที่บ่าวสาวจะต้องมีอาการตื่นเต้นเมื่อวันแต่งงานเริ่มเข้าใกล้เข้ามา
            จนในบางครั้งอาจจะทำให้หลงลืมสิ่งสำคัญสำหรับการจัดงานเอาเสียดื้อๆ ซึ่งถ้าคุณอยากจะให้งานแต่งงานของคุณออกมาสมบูรณ์แบบตามที่วาดฝันไว้
            คุณจะต้องเตรียมจดสิ่งสำคัญที่คุณห้ามลืมอย่างเด็ดขาดเอาไว้ในโทรศัพท์มือถือหรือสมุด Note โดยสิ่งต่างๆ
            ที่เรากำลังจะกล่าวถึงนั้น มันจะช่วยสร้างบรรยากาศภายในงานแต่งของคุณให้ออกมาสมบูรณ์แบบมากยิ่งขึ้นกว่าเดิม
            ดังนั้นไม่ให้เป็นการเสียเวลา เราไปดูกันเลยดีกว่าว่าสิ่งสำคัญเหล่านั้นมันมีอะไรบ้าง ?`,
    paragraph_1: `1.การตกต่างๆ ภายในงาน_
            การตกแต่งถือได้ว่าเป็นหัวใจสำหรับการจัดงานแต่งงานเลยก็ว่าได้ เพราะมันจะช่วยให้บรรยากาศโดยรอบ
            เต็มไปด้วยกลิ่นอายแห่งความรัก ซึ่งก็อยู่ที่บ่าวสาวแล้วล่ะว่า จะชอบการตกแต่งแบบไหนเป็นพิเศษ
            โดยอุปกรณ์ที่จะช่วยสร้างบรรยากาศให้สดชื่นและอบอุ่น ก็อาจจะเป็นพวกดอกไม้ ลูกโป่ง รูปภาพ Glitter เป็นต้น`,
    paragraph_2: `2.บทเพลงที่เอาไว้ขับกล่อมแขก_
            เรื่องเพลงก็ถือเป็นอีกเรื่องที่คุณจะต้องใส่ใจเป็นพิเศษ เพราะเราบอกได้เลยว่าบทเพลงต่างๆ นั้น
            จะช่วยเพิ่มอรรถรสให้แขกภายในงานอิ่มเอมไปกับความรักของคุณทั้งสองคน โดยแนวเพลงอาจจะเป็นเพลงที่ฟังสบายๆ
            ในแนวความรัก ซึ่งคุณอาจจะจ้างวงดนตรีหรือเปิดเพลงเอาก็ได้ตามความสะดวก ยิ่งถ้าเป็นช่วงที่เปิดตัวบ่าว/สาว ด้วยแล้ว
            คุณจะต้องเลือกบทเพลงที่มีความหมายและสามารถดึงดูดใจให้แขกหันมาสนใจคุณให้ได้มากที่สุด`,
    paragraph_3: `3.เจ้าหน้าที่คอยดูแลแขก_
            บอกได้เลยว่าบ่าวสาวหลายๆ คู่พลาดในเรื่องมากที่สุด! เพราะบางคนมัวแต่ต้อนรับแขก
            จนไม่ได้สนใจว่าพนักงานที่เราจ้างมาหรือที่ทางโรงแรมจัดให้นั้นดูแลแขกได้อย่างทั่วถึงหรือไม่ ?
            เสิร์ฟอาหารได้ตรงเวลาหรือเปล่า ? ซึ่งสิ่งต่างๆ เหล่านี้ คุณอาจจะใช้บุคคลที่ไว้ใจได้มาคอยคุมหน้างานให้คุณอีกที`,
    paragraph_4: `4.วิดีโอ Presentation_
            จุด Highlight ของงานแต่งงานก็คือวิดีโอ Presentation! ที่แขกทุกคนต่างตั้งตารอชม
            เพราะฉะนั้นแล้วคุณอาจจะต้องจ้างทีมงานมืออาชีพในการตัดต่อ หรืออาจจะทำ Presentation ในรูปแบบง่ายๆ
            ด้วยตัวของคุณเอง ยิ่งคุณทำในจุดนี้ได้ดีมากเท่าไหร่ มันก็จะยิ่งเป็นหน้าเป็นตาของงานแต่งคุณได้มากเท่านั้น แต่!
            สำหรับใครที่งบประมาณไม่ได้มีมากพอ ก็อาจจะเปลี่ยนเป็นการพูดคุยกันบนเวทีก็ได้เช่นกันไม่มีปัญหา`,
    paragraph_5: `5.Photo Booth_
            สิ่งที่จะสร้างสีสันให้กับงานแต่งงานของคุณได้เป็นอย่างดีนั่นก็คือ Photo Booth ที่จะช่วยทำให้แขกของคุณนั้นสามารถ Enjoy
            กับการถ่ายรูปในอิริยาบถต่างๆ ได้อย่างเต็มที่! อีกทั้งในปัจจุบันนี้ ผู้ให้บริการ Photo Booth ยัง มีการพิมพ์ชื่อหรือโลโก้ของงาน
            เพื่อให้แขกนั้นได้ทั้งรูปและของที่ระลึกจากงานคุณ!`,
    paragraph_6: `6.Dry ice & Bubble_
            หากคุณต้องการให้งานแต่งงานของคุณเหมือนดั่งกับเทพนิยาย สิ่งที่ขาดไม่ได้เลยก็คือ Dry ice & Bubble
            ที่จะเข้ามาช่วยให้บรรยากาศและภาพถ่ายนั้นสวยงามมากยิ่งขึ้นแบบทวีคูน โดยในเรื่องของ Dry ice & Bubble นั้น
            ส่วนมากทางสถานที่จัดงานแต่งงานจะเป็นคนจัดเตรียมไว้ให้ แต่! อย่าพึ่งชะล่าใจสอบถามรายละเอียดให้ดีเสียก่อน`,
    paragraph_7: `7.ที่จอดรถสำหรับแขก_
            ถ้าแขกที่มาร่วมงานแต่งงานของคุณไม่มีที่จอดรถ เราขอบอกเลยว่างานหยาบ!
            เพราะฉะนั้นแล้วคุณจะต้องจัดเตรียมที่จอดรถไว้ให้เพียงพอกับจำนวนแขก ซึ่งถ้าเป็นสถานที่จัดงานแต่งงานแบบมืออาชีพ
            ในเรื่องที่จอดรถก็คงจะไม่ใช่ปัญหาใหญ่อะไร แต่อาจจะมีการกั๊กที่จอดไว้ให้สำหรับแขก V.I.P `,
    paragraph_8: `8.ของชำร่วย_
            สิ่งที่บ่าวสาวส่วนใหญ่ชอบคำนวณผิดพลาดเป็นอันดับต้นๆ นั้นก็คือ ‘ของชำร่วย’
            โดยคุณจะต้องบวกจำนวนของชำร่วยเผื่อเอาไว้ประมาณ 100 ชิ้น! เพราะถ้าเหลือก็ยังเก็บไว้ได้
            แต่ถ้าขาดเมื่อไหร่แขกคงไม่พอใจอย่างแน่นอน อีกทั้งแขกบางคนอาจจะมีการขอของชำร่วยเพิ่มอีกด้วย`,
    paragraph_9: `9.ระแบบแสง สี เสียง ต้องพร้อม!_
            ระบบเสียงบนเวที ไฟส่องบ่าวสาว หรือ Effect ต่างๆ จะต้องไม่มีการผิดพลาด!
            เพราะหากบ่าวสาวกำลังพูดอยู่บนเวทีแล้วเกิดไมค์ไม่ติด ไฟดับขึ้น มันก็คงจะไม่ใช่เรื่องที่ดีเท่าไหร่นัก
            ดังนั้นคุณจึงจำเป็นจะต้องตรวจเช็ครายละเอียดตรงนี้กับทางทีมงานให้เรียบร้อยก่อนเริ่มงาน
            เพื่อลดโอกาสการผิดพลาดที่อาจจะเกิดขึ้นนั่นเอง`,

    final_chapter: `ทั้ง 9 อย่างนี้จะช่วยให้การจัดงานแต่งของคุณราบรื่นมากยิ่งขึ้นกว่าเดิม
        เพราะแขกภายในงานจะดื่มด่ำไปกับความรักของคุณทั้ง 2 คนแบบเต็มอารมณ์ แต่!
        บางสิ่งบางอย่างคุณก็ไม่จำเป็นที่จะต้องจัดเตรียมเอง เช่น พนักงานดูแลแขก ที่จอดรถ Dry ice & Bubble ระบบแสง สี เสียง
        ฯลฯ เพราะสิ่งต่างๆ เหล่านี้ ทางโรงแรมหรือทางสถานที่จัดงานแต่งจะเป็นคนคอยจัดเตรียมไว้ให้คุณเรียบร้อยแล้ว
        เพียงแค่คุณอาจจะต้องคอนเฟิร์มกับทางเจ้าหน้าที่ให้เรียบร้อยก่อนเริ่มงาน
        อีกทั้งคุณจะต้องเลือกสถานที่จัดงานแต่งที่มีทีมงานมืออาชีพไว้คอยบริการคุณด้วย
        ซึ่งทั้งหมดทั้งมวลนี้จะเป็นที่ไหนไปไม่ได้เลยนอกจากคุ้มเจ้าสัว สถานที่จัดงานแต่งงาน ที่กำลังได้รับความนิยมอยู่ในขณะนี้
        โดยคุณสามารถติดต่อ/สอบถามรายเอียดได้ที่ เบอร์ 086 704 9201 ตลอดเวลาทำการ`,

    ContinueRead: "Continue Reading",
  },
  {
    article: "ห้ามละเลยอย่างเด็ดขาด! กับ 7 ข้อสำคัญในการจัดงานแต่งงาน",
    id: 2,
    image: articleImg02,
    introduction: `เพราะการจัดงานแต่งงานนั้น ล้วนมีรายละเอียดในเรื่องต่างๆ มากมาย
            จนอาจจะทำให้บ่าวสาวมือใหม่เกิดความสับสน มึนงงหรือหลงลืมเรื่องที่สำคัญเอาได้!
            และนั่นก็อาจจะทำให้งานแต่งงานไม่เป็นไปตามอย่างที่คาดหวังเอาไว้ เพราะฉะนั้นแล้วในวันนี้เราจึงได้ทำการรวบรวม 7 ข้อสำคัญ
            ที่บ่าวสาวห้ามละเลยหรือห้ามลืมอย่างเด็ดขาดมาฝาก! ซึ่งขอบอกเลยว่าแต่ละข้อนั้นมันเป็นเรื่องที่สำคัญเป็นอันดับต้นๆ
            ในการจัดงานแต่งงานเลยล่ะ และทั้ง 7 ข้อจะมีอะไรที่คุณจะต้องจด ต้องจำบ้าง เราไปติดตามพร้อมๆ กันเลย!`,
    paragraph_1: `1.อย่าเห็นแก่ของถูก_
            ในช่วงเวลาเริ่มต้นของการจัดงานแต่งงานนั้น เป็นธรรมดาที่บ่าวสาวจะต้องเข้าไปติดต่อกับผู้ให้บริการทางด้านต่างๆ
            ไม่ว่าจะเป็นเรื่องชุด การถ่ายภาพพรีเวดดิ้ง สถานที่ ฯลฯ ซึ่งแน่นอนว่าคุณจะได้พบกับโปรโมชั่นลด แลก แจก แถมแบบสุดๆ
            แต่! เราอยากให้คุณจำไว้อย่างหนึ่งว่าอย่าเห็นแก่ของถูก! บางอย่างถ้าประหยัดได้ก็ประหยัด
            แต่บางอยากถ้าจำเป็นจะต้องจ่ายแพงกว่าก็ควรจะต้องจ่าย เพราะงานแต่งของคุณมีเพียงแค่ครั้งเดียวในชีวิต
            อย่าไปหลงเชื่อคำโฆษณา ให้ดูที่เนื้องานที่ผ่านมาของผู้ให้บริการว่าเขาทำงานได้ดีหรือไม่อย่างไร แล้วค่อยตัดสินใจอย่าพึ่งรีบร้อน!`,
    paragraph_2: `2.พิธีกรคือส่วนสำคัญ ในการดำเนินงาน_
            อีกหนึ่งสิ่งที่บ่าวสาวชอบละเลยมากที่สุด ก็คงจะเป็นเรื่องของพิธีกรภายในงาน! คิดแค่ว่าเอาคนรู้จักมาพูดก็ได้ สบายๆ แต่!
            ในความเป็นจริงแล้ว พิธีกรคือหัวเรือใหญ่ในการขับเคลื่อนพิธีการต่างๆ ให้เป็นไปตามแบบแผนที่วางไว้
            ซึ่งถ้าเกิดเอาคนที่ไม่รู้ขั้นตอนมาพูด บอกได้เลยว่างานพัง! เพราะเขาไม่จะรู้จังหวะในการพูด ในการ Entertain แขก
            เรียกได้ว่าถ้าพิธีกรดี งานแต่งของคุณจะมีสีสันเพิ่มขึ้นอีกเป็นเท่าตัวเลยล่ะ`,
    paragraph_3: `3.รสชาติของอาหารคือหน้าตาของงานคุณ_
            อย่าพึ่งไว้ใจทีมงานที่รับหน้าที่ทำอาหาร ถ้าคุณยังไม่ได้ลองชิม!
            เรื่องอาหารในงานแต่งงานถือได้ว่าเป็นเรื่องที่บ่าวสาวไม่ควรจะละเลยเป็นอย่างยิ่ง
            เพราะฉะนั้นแล้วเมื่อทำการจองสถานที่เรียบร้อย คุณจะต้องเข้าไปชิมอาหารตามที่ตกลงเอาไว้
            ว่าจะต้องลดหรือเพิ่มรสชาติอาหารแบบไหนเป็นพิเศษ หรือในกรณีที่จ้างโต๊ะจีนหรือจ้างทำอาหาร
            คุณจะต้องเลือกสรรเมนูที่แขกสามารถทานได้ทุกคน`,
    paragraph_4: `4.ความประทับใจเล็กๆ มาจากของชำร่วย_
            เรื่องเบสิกที่ถูกมองข้ามอยู่บ่อยครั้งอย่างของชำร่วยนั้น แม้ว่ามันจะดูเป็นเรื่องที่เล็กน้อย แต่!
            มันคือตัวแทนของบ่าวสาวในการขอบคุณแขกที่มาร่วมงาน ฉะนั้นคุณจะต้องเลือกของชำร่วยที่มีความหมายดี ใช้งานได้จริง
            เพื่อเป็นการตอบแทนแขกที่ให้เกียตริสละเวลาในการมาเป็นสักขีพยานในงานแต่งงานของคุณ`,
    paragraph_5: `5.ต้องใส่ใจในการเลือกช่างภาพ&วิดีโอ_
            ช่างภาพคนนี้ถ่ายดี ราคาถูก มัดจำเงินไปเลย! ถ้าบ่าวสาวคู่ไหนมีพฤติกรรมแบบนี้ล่ะก็ หยุด!
            เพราะนั่นอาจจะทำให้คุณได้ช่างภาพงานแต่งที่ไม่ตรงใจเอาได้
            ซึ่งอย่างที่เรารู้กันดีว่าช่างภาพแต่ละคนล้วนมีสไตล์การถ่ายรูปที่แตกต่างกันออกไป
            ดังนั้นคุณจึงจำเป็นที่จะต้องมองหาช่างภาพที่มีสไตล์การถ่ายรูปที่ถูกตาต้องใจของคุณให้ได้มากที่สุด!
            อีกทั้งให้คุณระลึกไว้เสมอเลยว่า ภาพถ่ายในงานแต่งงานของคุณจะกลายเป็นภาพแห่งความทรงจำที่งดงามเมื่อวันเวลาผ่านไป
            ดังนั้นการเลือกช่างภาพจึงเป็นเรื่องที่คุณต้องใส่ใจและพิถีพิถันเป็นพิเศษ!`,
    paragraph_6: `6.ตรวจสอบรายละเอียดของการ์ดให้ถี่ถ้วน_
            ก่อนที่จะเริ่มแจกการ์ดงานแต่งงานให้แขกนั้น คุณจำเป็นจะต้องเตรียมเช็ครายละเอียดต่างๆ ให้ดีเสียก่อน ไม่ว่าจะเป็น การสะกดชื่อ
            พ่อ-แม่บ่าวสาว วันเวลา สถานที่ หรือแม้แต่การตรวจคำผิด! ซึ่งบ่าวสาวส่วนใหญ่ต่างไว้ใจโรงพิมพ์ พอได้การ์ดมาปุ๊ป แจกปั๊ป
            สรุปเขียนผิด ดังนั้นตรวจเช็คให้ดีก่อนที่จะเดินทางไปแจกการ์ด`,
    paragraph_7: `7.เรื่องรองเท้าโปรดอย่ามองข้าม!_
            แค่รองเท้าจะต้องใส่ใจอะไรกับมันด้วย ? บ่าวสาวคู่ไหนที่กำลังคิดแบบนี้อยู่ เราขอให้คุณเปลี่ยนความคิดแบบด่วนๆ
            เพราะตลอดเวลาที่จัดอยู่นั้น คุณจะต้องยื่นรับแขก เดินไปทักทายแขกตามโต๊ะ
            ซึ่งถ้าเกิดใส่รองเท้าที่ไม่ได้มาตรฐานหรือเกิดรองเท้ากัดขึ้นมา คุณก็ลองคิดดูซิว่ามันจะทรมานสักแค่ไหน
            จะเปลี่ยนหรือจะถอดกลางคันก็คงไม่ใช่เรื่อง ดังนั้นเลือกรองเท้าที่ใส่สบายคุณจะได้ Enjoy กับงานแต่งงานได้อย่างเต็มที่!`,
    paragraph_8: ``,
    paragraph_9: ``,

    final_chapter: `เห็นไหมล่ะว่าทั้ง 7 ข้อนั้น มีความสำคัญในการจัดงานแต่งงานเป็นอย่างมาก!
            โดยทางทีมงานหวังว่าบ่าวสาวมือใหม่คงจะได้ประโยชน์และแนวทางในการจัดงานแต่งงานที่กำลังจะถึงจากข้อมูลที่เรากล่าวไปข้างต้นนี้
            อีกทั้งถ้าคุณต้องการที่จะให้งานแต่งงานของคุณสมบูรณ์แบบและ Prefect มากที่สุด
            ก็อย่าลืมเลือกสถานที่ที่มีความพร้อมในการรับรองแขก ไม่ว่าจะเป็นเรื่องห้องน้ำ ที่จอดรถ พนักงานเสิร์ฟหรือแม้แต่เรื่องอาหาร
            ซึ่งก็จะเป็นสถานที่ไหนไปไม่ได้เลยนอกจากคุ้มเจ้าสัว สถานที่จัดงานแต่งงานที่มีความพร้อมเป็นอันดับต้นๆ ที่บ่าวสาวหลายคนไว้วางใจ!
            เพราะทางคุ้มเจ้าสัว มีพนักงานมืออาชีพคอยบริการและทำตามความต้องการของบ่าวสาวอย่างได้อย่างตรงจุด!
            โดยคุณสามารถสอบถามรายละเอียดได้ทาง 086 704 9201 รับรองเลยว่าการจัดงานแต่งงานของคุณจะกลายเป็นเรื่องง่าย
            หากคุณเลือกใช้บริการคุ้มเจ้าสัว`,

    ContinueRead: "Continue Reading",
  },
  {
    article:
      "บ่าวสาวต้องรู้! กับ 8 ของชำร่วย ที่จะช่วยเพิ่มความประทับใจให้กับแขก",
    id: 3,
    image: articleImg03,
    introduction: `ในการจัดงานแต่งงานนั้นนอกจากเรื่องต่างๆ ที่บ่าวสาวต้องดูแลแล้ว
            ยังมีอีกหนึ่งเรื่องที่สำคัญที่ลืมไม่ได้เลยก็คือ “ของชำร่วย” ซึ่งของชำร่วยเปรียบเสมือนคำขอบคุณจากบ่าวสาวที่มอบจะให้กับแขกที่มาร่วมงาน
            ดังนั้นการเลือกของชำร่วยจึงกลายเป็นเรื่องที่บ่าวสาวไม่ควรจะมองข้ามเป็นอย่างยิ่ง โดยหลักการเลือกของzชำร่วยนั้นไม่ได้มีกฎเกณฑ์ที่ตายตัว
            จะเน้นไปที่ความชอบของบ่าวสาวเป็นส่วนใหญ่! แต่ถ้าจะให้ดีของชำร่วยนั้นจะต้องสร้างความประทับให้กับแขกได้
            ซึ่งถ้าคุณไม่รู้ว่าจะเลือกของชำร่วยเป็นอะไรดี ในบทความนี้ก็จะพาคุณไปพบกับ 8 ของชำร่วย
            ที่จะช่วยเพิ่มความประทับให้กับแขก และของชำร่วยอย่างแรกที่เราจะแนะนำก็คือ…`,
    paragraph_1: `1.ช้อน-ส้อม/ตะเกียบ_
            ของชำร่วยอย่างแรกที่เราจะแนะนำก็คือ ‘ช้อน/ส้อม’ โดยมีความเชื่อว่าช้อนส้อมนั้นเป็นของคู่กัน
            เปรียบเสมือนเจ้าบ่าวและเจ้าสาว อีกทั้งช้อนส้อมยังสามารถนำไปใช้งานได้จริงๆ อีกด้วย
            ส่งผลให้ช้อนส้อมกลายเป็นของชำร่วยยอดนิยมตั้งแต่อดีตมาจนถึงปัจจุบัน
            ยิ่งในสมัยนี้มีช้อนส้อมหลากหลายรูปแบบให้บ่าวสาวได้เลือก ไม่ว่าจะเป็นช้อนส้อมไม้ ช้อนส้อมสีทอง
            หรือแม้แต่การสลักชื่อลงบนช้อนส้อมก็ทำได้เช่นเดียวกัน`,
    paragraph_2: `2.สเปย์แอลกอฮอล์/หน้ากากอนามัย_
            หากจะไม่เอ่ยถึงของชำร่วยที่อินเทรนด์แบบสุดๆ อย่าง สเปย์แอลกอฮอล์และหน้ากากอนามัยก็คงจะไม่ได้!
            เพราะอย่างที่เราทราบกันดี ว่าในปัจจุบันนี้ผู้คนหันมาให้ความสนใจและใส่ใจกับเรื่องของสุขอนามัยมากยิ่งขึ้น
            ทำให้สเปย์แอลกอฮอล์และหน้ากากอนามัยกลายเป็นของชำร่วยที่ดูเก๋ไก๋และสามารถใช้งานได้จริงมากที่สุด
            อีกทั้งรูปแบบของสเปย์แอลกอฮอล์ก็มีให้คุณเลือกอยู่หลากหลายรูปแบบ คราวนี้ผู้รับปลอดภัย ผู้ให้สุขใจอย่างแน่นอน!`,
    paragraph_3: `3.เครื่องหอมชนิดต่างๆ_
            เครื่องหอมในที่อาจจะเป็น เทียนหอม น้ำหอมพกพา พวงกุญแจน้ำหอม ฯลฯ
            ซึ่งแน่นอนว่าความหอมของมันจะทำให้แขกที่ได้รับของชำร่วยไปนั้น จะต้องประทับใจแถมมันยังสามารถใช้งานได้จริงอีกด้วย
            และที่สำคัญที่ขาดไม่ได้เลยคือความหอมของเครื่องหอมชนิดต่างๆ จะมีความหมายที่สื่อถึงความหอมหวานในชีวิตคู่นั่นเอง`,
    paragraph_4: `4.กระเป๋าใบเล็ก_
            ของชำร่วยยอดนิยมอีกหนึ่งอย่างนั่นก็คือกระเป๋าใบเล็กๆ เอาไว้ใส่เศษเหรียญ
            ซึ่งสีสันของของชำร่วยชนิดนี้ก็มีให้บ่าวสาวเลือกอยู่มากมาย ซึ่งสีที่ได้รับความนิยมแบบสุดๆ ก็คือสีชมพูและสีทอง
            อีกทั้งความหมายของมันก็ดีแบบสุดๆ เพราะการที่ให้กระเป๋าเป็นของชำร่วยแก่แขกที่มาร่วมงานนั้น หมายถึงการเก็บเงินเก็บทอง
            พร้อมรับโชคลาภ ยิ่งถ้าเป็นกระเป๋าที่มีดอกไม้ประดับก็จะเป็นการเสริมดวงในเรื่องของความรักอีกด้วย`,
    paragraph_5: `5.ต้นไม้จิ๋ว_
            บ่าวสาวสายธรรมชาติจะต้องไม่พลาดกับของชำร่วยชิ้นนี้! นั่นก็คือต้นไม้จิ๋ว เพราะนอกจากความเก๋ไก๋แล้ว
            มันยังแสดงให้เห็นถึงความใส่ใจและคำขอบคุณที่จริงใจจากบ่าวสาว
            ซึ่งถ้าบ่าวสาวคู่ไหนอยากจะสร้างความแปลกใหม่และสร้างความประทับใจให้กับแขกที่มาร่วมงาน
            ของชำร่วยอย่างต้นไม้จิ๋วคงจะสามารถตอบโจทย์ความต้องการของคุณได้อย่างตรงจุดเลยล่ะ!`,
    paragraph_6: `6.สบู่_
            ไม่เชื่อก็ต้องเชื่อ! ว่าของชำร่วยอย่างสบู่นั้นได้รับความนิยมเพิ่มขึ้นในทุกๆ วัน! เพราะนอกจากมันจะสามารถใช้งานได้จริงแล้ว
            กลิ่นและสีสันของมันยังดึงดูดความสนใจของแขกได้เป็นอย่างดีอีกด้วย โดยในปัจจุบันนี้คุณสามารถออกแบบสบู่ได้อย่างอิสระ
            ไม่ว่าจะเป็นการเลือกกลิ่น เลือกสี เลือกทรง ซึ่งเรารับรองเลยว่าของชำร่วยชิ้นนี้จะต้องถูกใจแขกอย่างแน่นอน!`,
    paragraph_7: `7.ตาข่ายดักฝัน_
            ไม่ต้องทำหน้าสงสัยว่าตาข่ายดักฝันสามารถนำมาเป็นของชำร่วยได้จริงหรอ ? เพราะมีบ่าวสาวหลายๆ คู่
            ใช้เจ้าตาข่ายดักฝันนี่แหละเป็นคำขอบคุณแก่แขกผู้มาร่วมงาน ซึ่งความหมายของมันก็คงไม่ต้องบอกว่าดีขนาดไหน
            อีกทั้งของชำร่วยชิ้นนี้ยังได้รับความนิยมเป็นอย่างมากในต่างประเทศอีกด้วย`,
    paragraph_8: `8.กระจกเงา_
            กระจกเงาคงจะเป็นของชำร่วยที่แขกหลายๆ คน ต้องการมากที่สุด
            โดยกระจกเงาในที่นี้ต้องเป็นขนาดที่กะทัดรัดสามารถพกพาได้ มีการออกแบบที่สวยงาม
            ซึ่งนอกจากมันจะสามารถใช้งานได้จริงแล้ว
            กระจกเงายังจะช่วยเพิ่มความหรูหราและยกระดับให้กับงานแต่งของคุณได้อย่างไม่น่าเชื่อ
            เพราะส่วนมากแล้วกระจกที่นำมาเป็นของชำร่วยจะมีราคาที่สูงกว่าของชำร่วยชนิดอื่นๆ นั่นเอง`,
    paragraph_9: ``,

    final_chapter: `ของชำร่วยทั้ง 8 อย่างที่เรากล่าวไปนั้น
            ถือได้ว่าเป็นของชำร่วยที่จะช่วยให้แขกภายในงานแต่งงานของคุณเกิดความประทับใจได้อย่างแน่นอน!
            อีกทั้งหัวใจในการเลือกของชำร่วยอีกหนึ่งอย่างนั้นก็คือเรื่องงบประมาณ เอาแค่พอที่คุณจะจ่ายไหว
            ไม่เช่นนั้นมันอาจจะกลายเป็นอีกหนึ่งภาระค่าใช้จ่ายก้อนโตให้กับคุณเอาได้ เพียงแค่นี้ทั้งคุณและแขกก็จะ Happy
            กับงานแต่งงานได้แล้วล่ะ! อีกทั้งอย่าลืมมองหาสถานที่ที่จะช่วยอำนวยความสะดวกให้กับแขกได้อย่างครบครัน อย่างคุ้มเจ้าสัว
            สถานที่จัดงานแต่งงาน ที่กำลังมาแรงที่สุดในยุคนี้ เพราะคุ้มเจ้าสัว
            มีบริการที่พร้อมจะสร้างความประทับใจให้บ่าวสาวและแขกได้อย่างไม่มีขาดตกบกพร่อง โดยคุณสามารถสอบถามรายละเอียดต่างๆ
            ได้ทาง 086 704 9201`,

    ContinueRead: "Continue Reading",
  },
  {
    article: "8 โทนสีจัดงานแต่งสุดอลังการ! ที่มาแรงที่สุดในยุคนี้",
    id: 4,
    image: articleImg04,
    introduction: `บ่าวสาวคู่ไหน กำลังมองหาโทนสีจัดงานแต่งงานอยู่บ้างยกมือขึ้น! เรียกได้ว่าการเลือกโทนสีจัดงานแต่งงานนั้น
            เป็นอะไรที่สำคัญลำดับต้นๆ เลยก็ว่าได้ เพราะโทนการจัดงานนั้นจะทำให้ภาพรวมของงานแต่งออกมาสวยสดงดงามได้อย่างไม่น่าเชื่อเลยล่ะ!
            โดยสิ่งต่างๆ ที่จะต้องทำการคุมโทนก็อาจจะเป็นพวก ผ้าปูโต๊ะ ผ้าผูกเก้าอี้ ดอกไม้ ของตกแต่งต่างๆ รวมไปถึงสีชุดของแขกผู้มาร่วมงาน แต่!
            สำหรับบ่าวสาวคู่ไหนที่ยังคิดไม่ตกว่าจะเลือกโทนสีจัดงานแต่งสีอะไรดี ในบทความนี้ก็ได้รวบรวม 8
            โทนสีจัดงานแต่งที่มาแรงที่สุดในยุคนี้มาฝาก รับรองเลยว่าคุณจะได้ไอเดียเด็ดๆ ในการจัดงานแต่งกลับไปประยุกต์ใช้อย่างแน่นอน!`,
    paragraph_1: `1.Rose Gold_
            โทนสีจัดงานแต่งงานที่กำลังมาแรงที่สุดในยุคนี้ก็คงจะหนีไม่พ้น สี Rose Gold เพราะเจ้าสีๆ นี้นั้น มันบ่งบอกถึงความหรูหรา
            แต่ยังแอบแฝงไปด้วยความอ่อนหวาน มันจึงเหมาะกับการเป็นสีที่อยู่ในงานแต่งงานมากที่สุด! อีกทั้งสี Rose Gold
            ยังสามารถช่วยเพิ่มบรรยากาศภายในงานแต่งของคุณ ให้รู้สึกอบอวนไปด้วยความรักที่อ่อนละมุนได้อย่างไม่น่าเชื่อเลยทีเดียว!`,
    paragraph_2: `2.สีแดง_
            บ่าวสาวสายแซ่บ! จะต้องไม่พลาดกับโทนสีจัดงานแต่งงานสีแดงเป็นอันขาด เพราะนอกจากสีแดงจะเป็นสีแห่งความรักแล้ว
            มันยังเป็นสีที่บ่งบอกถึงพลัง ถึงอำนาจของบ่าวสาวอีกด้วย แต่! ถ้าไม่อยากจะให้ภายในงานนั้นดูจี๊ดจ๊าดจนเกินไป
            คุณอาจจะเปลี่ยนเป็นสีโทนแดงไวท์ แดงเลือดหมู ก็ได้เช่นกัน
            ซึ่งเราขอบอกเลยว่าโทนสีจัดงานแต่งสีแดงนั้นจะส่งผลให้ภาพรวมของงานดูเก๋ไก๋ได้อย่างสุดๆ เลยล่ะ!`,
    paragraph_3: `3.สีน้ำเงิน/สีฟ้า_
            มาต่อกันที่โทนสีจัดงานแต่งงานแบบสายชิล เน้นให้บรรยากาศภายในงานผ่อนคลาย ซึ่งจะเป็นสีไหนไปไม่ได้เลยนอกจากสีฟ้า
            หรือถ้าอยากจะเสริมความหรูหราให้กับงานโทนสีน้ำเงินก็คงจะสามารถตอบโจทย์ความต้องการของคุณได้เป็นอย่างดี
            อีกทั้งสีน้ำเงินยังสามารถใส่ได้ทั้งเพื่อนเจ้าบ่าวและเพื่อนเจ้าสาวอีกด้วย เห็นไหมล่ะว่าโทนสีจัดงานแต่งงานสีนี้มันคูลสุดๆ ไปเลย!`,
    paragraph_4: `4.สีควันบุหรี่_
            โทนสีจัดงานแต่งงานสุดคลาสสิก ที่แอบแฝงไปด้วยความสุขุมและเรียบหรูอย่างสีควันบุหรี่นั้น ถือได้ว่าเป็นโทนสียอดนิยม
            ที่บ่าวสาวแนวมินิมอลหลายๆ คู่เลือกใช้ แต่! ถ้าใครคิดว่ามันจะเรียบจนเกินไป ก็อาจจะมีการนำสีอื่นๆ เข้ามาตัดเพื่อสร้างสีสัน
            เช่น สีเลือดหมู สีน้ำเงิน เป็นต้น`,
    paragraph_5: `5.สีทอง/สีเงิน_
            บอกได้เลยว่าถ้างานแต่งงานงานไหนใช้โทนสีเงิน สีทองในการประดับสถานที่หรือเป็นธีมของสีเสื้อผ้าแล้วล่ะก็
            บรรยากาศภายในงานนั้นจะอลังการแบบสุดๆ ไปเลยล่ะ เพราะมองไปทางไหนมันก็วิบวับจับใจซะเหลือเกิน และทั้ง 2 สีนี้
            ยังถือว่าเป็นสีมงคล ที่จะเข้ามาช่วยให้บ่าวสาวมีเงิน มีทองใช้ ในการเริ่มต้นชีวิตคู่อีกด้วย`,
    paragraph_6: `6.สีชมพู_
            โทนสีจัดงานแต่งที่ขาดไม่ได้เลยก็คือสีชมพู! เจ้าสาวสายหวาน เจ้าบ่าวสายแบ๊ว
            จะต้องประทับใจและหลงรักกับบรรยากาศภายในงานอย่างปฏิเสธไม่ได้ เพราะมองไปทางไหน มันก็ดูอบอุ่น ดูหวานไปซะหมด
            ซึ่งสีชมพูนี้เป็นอีกหนึ่งสีที่บ่าวสาวนิยมเลือกใช้มากที่สุดเลยก็ว่าได้!`,
    paragraph_7: `7.สีขาว/สีครีม_
            ชอบเรียบๆ ชอบคลีนๆ โทนสีจัดงานแต่งงานสีขาว สีครีมคงจะเหมาะกับคู่ของคุณมากที่สุด!
            เพราะนอกจากมันจะให้ความสบายตาแล้ว โทนสีนี้ยังจะช่วยให้ภาพรวมของงานนั้นดูดี ดูมีระดับ
            โดยที่คุณแทบจะไม่ต้องทำอะไรให้มากมายเลย`,
    paragraph_8: `8.สีเขียว_
            บ่าวสาวสายธรรมชาติ จะพลาดไม่ได้เลยกับโทนสีจัดงานแต่งงานสีเขียว แต่! ถ้าจะให้เราแนะนำ
            สีเขียวของคุณนั้นจะต้องเป็นสีเขียวเข้ม เขียวมรกต เพื่อเพิ่มความหรูหราให้กับบรรยากาศภายในงาน ซึ่งถ้าเป็นสีเขียวสด
            มันจะดูไม่ค่อยเข้ากับงานแต่งงานสักเท่าไหร่นัก ดังนั้นสีเขียวเข้มจึงเป็นทางเลือกที่ดีที่สุดของบ่าวสาวสายธรรมชาติ!`,
    paragraph_9: ``,

    final_chapter: `ทั้ง 8 โทนสีจัดงานแต่งงานที่เรากล่าวไปนั้น
            ถือได้ว่าเป็นโทนสีจัดงานแต่งที่กำลังมาแรงแบบสุดๆ อีกทั้งการที่คุณจะจัดงานแต่งให้ภาพรวมออกมาสวยงาม
            คุณจะต้องพยายามให้ทุกสิ่งทุกอย่างมีสีสันเป็นไปในแนวทางเดียวกัน อาจจะมีการเพิ่มโทนสีที่ตัดกัน
            มาแทรกเพื่อเพิ่มความเก๋ไก๋ให้กับงานแต่งของคุณก็ได้ ไม่มีปัญหา เช่น ธีมสีแดงอาจจะตัดด้วยสีขาว
            ธีมสีน้ำเงินอาจจะตัดด้วยสีเหลือง เป็นต้น แต่สิ่งที่สำคัญไม่แพ้โทนสีจัดงานแต่งคือสถานที่ที่จัดงานและออแกไนซ์
            ยิ่งถ้าได้ทีมงานมืออาชีพ สถานที่มีความพร้อม ก็จะยิ่งเป็นการเพิ่มความสมบูรณ์ให้กับงานแต่งของคุณ
            ซึ่งก็จะเป็นที่ไหนไปไม่ได้เลยนอกจากคุ้มเจ้าสัว สถานที่จัดงานแต่งพร้อมทีมงานเฉพาะทาง
            ที่จะคอยเนรมิตภาพงานแต่งในฝันของคุณให้กลายเป็นจริง! โดยคุณสามารถติดต่อสอบถามได้ที่ 086 704 9201`,

    ContinueRead: "Continue Reading",
  },
  {
    article: "8 เทคนิคการจัดงานแต่งงานให้ปังปุริเย่! ในแบบฉบับบ่าว/สาวมือใหม่",
    id: 5,
    image: articleImg05,
    introduction: `สิ่งที่ทำให้บ่าวสาวมือใหม่เป็นกังวลมากที่สุด ก็คงจะหนีไม่พ้นเรื่องการจัดงานแต่งงาน
            ว่าจะจัดออกอย่างไรให้มันสมบูรณ์แบบมากที่สุด! ไม่ว่าจะเป็นเรื่องการรับรองแขก อาหาร แสง สี เสียง ฯลฯ
            ซึ่งก็เป็นอย่างที่เรารู้กันดีว่า รายละเอียดการจัดงานแต่งงานนั้นมีมากมาย แต่! คุณก็ไม่ต้องเป็นกังวลใจไปเพราะในบทความนี้
            เรามี 8 เทคนิคสำคัญในการจัดงานแต่งงานให้ปังปุริเย่มากฝาก!
            โดยทั้ง 8 เทคนิคนั้นจะช่วยให้งานแต่งงานของคุณออกมางดงามเหมือนดังที่ฝันไว้อย่างแน่นอน
            เพราะฉะนั้นไม่ให้เป็นการเสียเวลาเราไปพบกับเทคนิคข้อที่ 1 กันเลย!`,
    paragraph_1: `1.ตั้งงบประมาณให้ชัดเจน_
            เทคนิคแรกในการจัดงานแต่งงานนั่นก็คือ คุณจะต้องตั้งงบประมาณในการจัดงานครั้งนี้ให้ชัดเจนเสียก่อน!
            เพราะบ่อยครั้งที่บ่าวสาวไม่ได้ระวังการใช้จ่าย จนทำให้งบบานปลายในที่สุด! ซึ่งนั่นก็คงจะไม่ใช่เรื่องที่ดีเท่าไหร่นัก
            ดังนั้นให้คุณวางแผนคล่าวๆ ก่อนว่าต้องใช้จ่ายในเรื่องใดบ้าง แล้วค่อยมากำหนดว่าจะให้งบในแต่ละเรื่องเท่าไหร่
            เพื่อที่คุณจะได้คุมงบประมาณไม่ให้เกินที่วางแผนไว้`,
    paragraph_2: `2.รีบสรุปรายชื่อแขกทั้งหมด_
            ยิ่งคุณสรุปรายชื่อแขกได้ไวมากเท่าไหร่ การจัดงานแต่งงานของคุณก็จะไวมากขึ้นเท่านั้น!
            เพราะคุณจะสามารถกำหนดรายละเอียดต่างๆ ของงานได้รวดเร็วมากยิ่งขึ้นกว่าเดิม ไม่ว่าจะเป็นการจองโต๊ะจีน/บุฟเฟ่ต์
            จองสถานที่ พิมพ์การ์ด เตรียมของชำร่วย โดยสิ่งต่างๆ เหล่านี้ต่างต้องมีจำนวนแขกที่แน่นอนซะก่อนถึงจะเริ่มการสั่งทำได้นั่นเอง!`,
    paragraph_3: `3.กำหนดพิธีการให้เข้าใจกันทั้งฝ่ายเจ้าบ่าวและเจ้าสาว_
            ถ้าเจ้าบ่าวและเจ้าสาวเป็นคนไทยแท้ทั้งคู่เรื่องพิธีรีตองก็คงจะไม่ค่อยเป็นปัญหาเท่าไหร่นัก แต่! ถ้าฝ่ายใดฝ่ายหนึ่งมีเชื้อสายจีน
            อิสลามหรือนับถือศาสนาคริสต์ ก็คงจะต้องมีการพูดคุยกัน ว่าพิธีจะไปในแนวทางไหน ? ซึ่งถ้าทั้งสองตรงตกลงกันได้
            การจัดงานแต่งงานนั้นก็จะราบรื่น ไม่มีปัญหาระหว่างบ้านมากวนใจอย่างแน่นอน`,
    paragraph_4: `4.เลือกธีม&โทนสีของงานไว้ตั้งแต่เนิ่นๆ_
            การเลือกโทนสีของงานหรือธีมงานว่าต้องการที่จะให้ออกมาเป็นแนวทางไหน
            ก็เป็นอีกหนึ่งเรื่องที่บ่าวสาวจะต้องเตรียมคิดไว้ตั้งแต่เนิ่นๆ เพื่อที่จะได้บอกกับทางผู้จัดงานว่าต้องการให้มันออกมาเป็นแนวไหน
            โดยคุณอาจจะหา Raf ให้ทางผู้จัดงานหรือออแกไนซ์ดูเป็นเพื่อตัวอย่างก็ได้เช่นกัน`,
    paragraph_5: `5.ติดต่อวงดนตรี พิธีกร ช่างแต่งหน้า ให้พร้อม_
            มองเผินๆ เรื่องนี้อาจจะดูไม่ได้สำคัญอะไรนัก แต่! ในความเป็นจริงแล้วการจัดงานแต่งงานให้ปังปุริเย่นั้น หน้าต้องพร้อม!
            ดนตรีต้องดี! พิธีกรต้องเด็ด! เพราะฉะนั้นเมื่อได้ฤกษ์งานแต่งมาแล้ว คุณควรจะรีบทำการจองคิววงดนตรี ช่างแต่งหน้า
            และพิธีกรไว้ได้เลย ไม่เช่นนั้นถ้าปล่อยเวลาผ่านไปเรื่อยๆ คิวของทีมงานที่คุณต้องการอาจจะเต็มเอาได้! เราขอเตือนไว้ก่อน`,
    paragraph_6: `6.เลือกชุดแต่งงานที่คุณมั่นใจมากที่สุด_
            อีกหนึ่งองค์ประกอบสำคัญสำหรับการจัดงานแต่งงานนั้น ก็คงจะหนีไม่พ้นเรื่องชุดของเจ้าบ่าวและเจ้าสาว
            ซึ่งในเรื่องนี้ทางเจ้าบ่าวอาจจะไม่ได้ซีเรียสมากเท่าไหร่นัก แต่! สำหรับเจ้าสาวแล้ว
            ให้คุณเลือกชุดแต่งงานที่คุณชอบและเหมาะสมกับตัวคุณให้ได้มากที่สุด
            เพราะมันจะเป็นอีกส่วนหนึ่งของความทรงจำที่ติดตัวคุณไปยันหมดลมหายใจเลยล่ะ เพราะฉะนั้นเลือกให้ดี เลือกให้ถูกใจ
            ใครจะว่าอย่างไร ก็อย่าไปแคร์!`,
    paragraph_7: `7.ใส่ใจในการเลือกช่างภาพสักนิด!_
            ก่อนที่คุณจะตัดสินใจเลือกช่างภาพนั้น คุณจำเป็นที่จะต้องเข้าไปดูสไตล์การถ่ายรูปของเขาก่อน ว่ามีมุมกล้อง โทนสี
            ที่ถูกตาต้องใจของคุณมากน้อยเพียงใด เพราะช่างภาพแต่ละคนนั้นมีสไตล์การถ่ายรูปที่แตกต่างกัน
            ซึ่งในเรื่องนี้ถือได้ว่าเป็นเรื่องสำคัญที่บ่าวสาวจะต้องใส่ใจเป็นพิเศษ และที่สำคัญงานแต่งงานมีครั้งเดียว
            รูปภาพทุกรูปจึงเป็นสิ่งที่ล้ำค่า ฉะนั้นใส่ใจรายละเอียดตรงนี้สักนิด เพื่อภาพบรรยากาศงานแต่งงานของคุณ
            จะได้เป็นภาพที่สมบูรณ์แบบมากที่สุดในความทรงจำ`,
    paragraph_8: `8.เพื่อนเจ้าบ่าว&เจ้าสาว คือแรงสำคัญในการช่วยงาน_
            ก่อนที่งานแต่งงานจะเริ่มขึ้น คุณอาจจะต้องขอแรงเพื่อนๆ ในการช่วยงานสักหน่อย ไม่ว่าจะเป็นการพาแขกเดินไปที่โต๊ะ
            การต้อนรับแขก การดูแลความเรียบร้อยโดยรวม ฯลฯ ซึ่งเพื่อนเจ้าบ่าว
            เจ้าสาวจะกลายเป็นผู้ช่วยสุดพิเศษให้กับงานแต่งงานของคุณได้อย่างไม่น่าเชื่อเลยล่ะ`,
    paragraph_9: ``,

    final_chapter: `ทั้ง 8 เทคนิคที่เรากล่าวไปนั้น จะช่วยทำให้งานแต่งงานของคุณปังปุริเย่อย่างแน่นอน!
            อีกทั้งการเตรียมงานของคุณก็จะเป็นขั้นเป็นตอนมากยิ่งขึ้นกว่าเดิม ซึ่งบ่าวสาวคู่ไหนที่กำลังกังวลใจเรื่องการจัดงานแต่งงานอยู่ล่ะก็
            คุณก็สามารถนำทั้ง 8 เทคนิคนี้ไปปรับประยุกต์ใช้ได้เลย
            และที่สำคัญการจัดหาสถานที่แต่งงานนั้นคุณจะต้องเลือกสถานที่ที่มีความพร้อมทั้งเรื่องที่จอดรถ พนักงาน ห้องน้ำและอาหาร
            ซึ่งก็คงจะเป็นที่ไหนไปไม่ได้เลยนอกจากคุ้มเจ้าสัว สถานที่จัดงานแต่งงานที่บ่าวสาวหลายๆ คู่ไว้วางใจ เพราะทางคุ้มเจ้าสัว
            มีความเป็นมืออาชีพในการดูแลจัดเตรียมงานแต่งงานให้ออกมาตรงกับความต้องการของบ่าวสาวมากที่สุด
            โดยคุณสามารถติดต่อ/สอบถามได้ที่เบอร์ 086 704 9201 “ให้งานแต่งงานของคุณกลายเป็นความทรงจำที่สวยงามกับคุ้มเจ้าสัว”`,

    ContinueRead: "Continue Reading",
  },
];
