import weddingPic04 from "../../assets/WeddingPicture/wedding-pic-04.jpg";
import weddingPic41 from "../../assets/WeddingPicture/wedding-pic-41.jpg";
import locationPic09 from "../../assets/WeddingPicture/wedding-pic-09.jpg";
import weddingPic32 from "../../assets/WeddingPicture/wedding-pic-32.jpg";
import weddingPic08 from "../../assets/WeddingPicture/wedding-pic-08.jpg";
import Carousel from "../Carousel/Carousel";

const slides = [
  { img: weddingPic04, link: "อัลบั้มภาพงานเเต่ง", path: "/wedding" },
  { img: weddingPic08, link: "อัลบั้มภาพงานเเต่ง", path: "/wedding" },
  { img: locationPic09, link: "อัลบั้มภาพงานเเต่ง", path: "/wedding" },
  { img: weddingPic32, link: "อัลบั้มภาพงานเเต่ง", path: "/wedding" },
  { img: weddingPic41, link: "อัลบั้มภาพงานเเต่ง", path: "/wedding" },
];

export default function WeddingSlide({ link }) {
  return <Carousel images={slides} refId={5} link={link} />;
}
